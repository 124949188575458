import React, {useState, useEffect} from 'react';
import { Button, Modal, Input, message, Tooltip } from 'antd';
import { EditOutlined, CopyOutlined, DownloadOutlined, InfoCircleOutlined, MailOutlined, LoadingOutlined, ExpandOutlined } from '@ant-design/icons';
import { useAuth } from '../../../auth/AuthContext';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import FullScreenModal from './FullScreenModal/FullScreenModal';
import axios from 'axios';

const IconButtonGroup = ({feature, text, fileId, fetchEditedVersions, setSelectedVersion, setActivePanel, versionId, contentType}) => {
    const auth = useAuth();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editedText, setEditedText] = useState(null);
    const [loading, setLoading] = useState(false)
    const [regenerateLoading, setRegenerateLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [fullscreenModalOpen, setFullscreenModalOpen] = useState(false);

    useEffect(() => {
        setEditedText(text)
    }, [text]);

    const handleEdit = async() => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('summary', editedText);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/summary`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'PATCH',
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          if (result?.version) {
            await fetchEditedVersions()
            setSelectedVersion(result.version); 
            setActivePanel('02')
          }         
        } else {
          message.error('Failed to edit Summary');
        }
      } catch (error) {
        console.error('Error editing Summary', error);
        message.error('Error editing Summary');
      }
      setLoading(false);
      setEditModalOpen(false);
    }

    const handleEditRegenerate = async() => {
      setRegenerateLoading(true);
      try {
        const formData = new FormData();
        formData.append('summary', editedText);
        if (versionId) formData.set('version_id', versionId);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/files/audio/${fileId}/summary`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'PATCH',
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          if (result?.version) {
            await fetchEditedVersions()
            setSelectedVersion(result.version); 
            setActivePanel('02')
          }         
        } else {
          message.error('Failed to edit Summary');
        }
      } catch (error) {
        console.error('Error editing Summary', error);
        message.error('Error editing Summary');
      }
      setRegenerateLoading(false);
      setEditModalOpen(false);
    }

    const markdownToPlainText = (markdown) => {
      return markdown
            .replace(/[#*_>`~]/g, '') // Remove markdown symbols
            .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Remove links, keep link text
            .replace(/\*\*(.*?)\*\*/g, '$1') // Remove bold, keep text
            .replace(/__(.*?)__/g, '$1') // Remove underline, keep text
            .replace(/!\[(.*?)\]\(.*?\)/g, '$1') // Remove images, keep alt text
            .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
            .replace(/\n\s*\n/g, '\n\n') // Remove extra newlines
            .trim(); // Trim leading/trailing whitespace
    }

    const stripHtmlTags = (html) => {
      // Replace <br> tags with newline characters
      const textWithLineBreaks = html.replace(/<br\s*\/?>/gi, '\n');
      
      // Remove all other HTML tags
      const textOnly = textWithLineBreaks.replace(/<\/?[^>]+>/gi, '');

      return textOnly;
    };

    const copyToClipboard = async () => {
      try {
          let copiedText;
          if (contentType === 'transcript'){
              copiedText = stripHtmlTags(text)
          } else if (contentType === 'summary') {
            copiedText = markdownToPlainText(text)
          } else {
            copiedText = text
          }
          await navigator.clipboard.writeText(copiedText);
          message.success('Copied!');
      } catch (err) {
          console.error('Failed to copy: ', err);
      }
    };

    const toCapitalCase = (str) => {
      return str
        .toLowerCase() // Convert the entire string to lowercase first
        .split(' ')    // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' ');    // Join the words back into a single string
    }

    const handleDownload = async() => {
      setDownloadLoading(true);
      try {
        const formData = new FormData();
        if (versionId) formData.set('version', versionId);
        formData.append('content_type', contentType);
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/download_summary`, formData, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${toCapitalCase(contentType)}.docx`); //${toCapitalCase(contentType)}
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (contentType === 'assessment_report') contentType = 'Assessment Report';   
        message.success(`${toCapitalCase(contentType)} downloaded successfully!`);

      } catch (error) {
        console.error(`Error downloading ${toCapitalCase(contentType)}`, error);
        message.error(`Error downloading ${toCapitalCase(contentType)}`);
      }
      setDownloadLoading(false);    
    }

    const handleSendEmail = async() => {
      setSendEmailLoading(true);
      try {
        const formData = new FormData();
        if (versionId) formData.set('version', versionId);
        formData.append('content_type', contentType);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/send_summary_email`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();   
          message.success(result.message);
        } else {
          message.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email', error);
        message.error('Error sending email');
      }
      setSendEmailLoading(false);
    }
    return (
      <>
      <div>
        {feature.edit && 
        <Tooltip placement="top" title="Edit">
          <Button 
            shape='circle'
            type="default" 
            icon={<EditOutlined style={{fontSize: "16px"}} />} 
            style={{ backgroundColor: "transparent", color: '#4CAF50', borderColor: 'transparent', width: "30px", height: "30px", boxShadow: "none" }}
            onClick={setEditModalOpen}
          />
        </Tooltip>}

        {feature.copy && 
        <Tooltip placement="top" title="Copy to Clipboard">
          <Button 
            shape='circle'
            type="default" 
            icon={<CopyOutlined style={{fontSize: "16px"}}  />} 
            style={{ backgroundColor: "transparent", color: '#2196F3', borderColor: 'transparent', width: "30px", height: "30px", boxShadow: "none"  }}
            onClick={copyToClipboard}
          />
        </Tooltip>}

        {feature.download && 
        <Tooltip placement="top" title="Download">
          <Button 
            // disabled
            shape='circle'
            type="default" 
            icon={downloadLoading? <LoadingOutlined style={{fontSize: "16px"}}  />:<DownloadOutlined style={{fontSize: "16px"}}  />} 
            style={{ backgroundColor: "transparent", color: '#FF5722', borderColor: 'transparent', width: "30px", height: "30px", boxShadow: "none"  }}
            onClick={handleDownload}
          />
        </Tooltip>}

        {feature.email && 
        <Tooltip placement="top" title="Send email">
          <Button 
            // disabled
            shape='circle'
            type="default" 
            icon={sendEmailLoading? <LoadingOutlined style={{fontSize: "16px"}} />:<MailOutlined style={{fontSize: "16px"}} />} 
            style={{ backgroundColor: "transparent", color: '#7200e5', borderColor: 'transparent', width: "30px", height: "30px", boxShadow: "none"  }}
            onClick={()=>setConfirmationModalOpen(true)}
          />
        </Tooltip>}
        {feature.fullscreen && 
        <Tooltip placement="top" title="View in Fullscreen">
          <Button 
            // disabled
            shape='circle'
            type="default" 
            icon={<ExpandOutlined style={{fontSize: "16px"}} />} 
            style={{ backgroundColor: "transparent", color: '#876603', borderColor: 'transparent',width: "30px", height: "30px", boxShadow: "none"  }}
            onClick={()=>setFullscreenModalOpen(true)}
          />
        </Tooltip>}
      </div>
      <Modal title="Edit Text" open={editModalOpen} onOk={handleEdit} onCancel={()=>setEditModalOpen(false)} okText="Save" cancelText="Cancel" centered width={800} maskClosable={false} keyboard={false} footer={[
            <Button key="cancel" onClick={()=>setEditModalOpen(false)}>
                Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleEdit}>
                Save
            </Button>,
            <Button key="submit" loading={regenerateLoading} onClick={handleEditRegenerate} style={{backgroundColor: "#ffa100", color: "#fff"}}>
                Save & Regenerate
            </Button>,
            
            ]}>
          <Input.TextArea rows={15} value={editedText} onChange={(e) => setEditedText(e.target.value)} />
          <small style={{color: "red", fontWeight: 600, fontStyle: "italic"}}><InfoCircleOutlined style={{marginRight: "10px"}} />Please maintain the same structure while editing.</small>
      </Modal>
      <ConfirmationModal open={confirmationModalOpen} setOpen={setConfirmationModalOpen} handleEmailSend={handleSendEmail} loading={sendEmailLoading} />
      <FullScreenModal open={fullscreenModalOpen} setOpen={setFullscreenModalOpen} text={text} />
      </>
      
    );
};
export default IconButtonGroup;
