// src/components/LinkedInLogin/LinkedInLogin.tsx
import React from 'react';
import { Button } from 'antd';
import linkedInLogo from '../../../assets/images/linkedin_logo.png'

const LinkedInLogin = () => {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/login/linkedin`;
  };

  return (
    <Button type="default" style={{width: "100%"}} onClick={handleLogin} size={'large'} ><img src={linkedInLogo} style={{width: "20px", height: "auto"}} alt="LinkedIn Logo" />Continue with LinkedIn</Button>
  );
};

export default LinkedInLogin;
