import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Button, message } from 'antd';
import { useAuth } from '../../../auth/AuthContext';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const EditPatientModal = ({ open, setOpen, patientId, setUpdated }) => {
    const auth = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        if (patientId && open) {
            fetchPatientDetails(patientId);
        }
    }, [patientId, open]);

    const fetchPatientDetails = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                
                const dob = dayjs(result.date_of_birth, 'YYYY-MM-DD');
                if (dob.isValid()) {
                    form.setFieldsValue({ date_of_birth: dob });
                } else {
                    console.error("Invalid date format:", result.date_of_birth);
                }

                form.setFieldsValue({
                    first_name: result.first_name,
                    last_name: result.last_name,
                    gender: result.gender,
                    email: result.email,
                    phone: result.phone,  // Set the phone value
                    quick_patient_notes: result.quick_patient_notes || '', // Set Quick Patient Notes
                });
            } else {
                const errorData = await response.json();
                message.error(errorData.detail?.message || 'Failed to fetch patient details');
            }
        } catch (error) {
            message.error('Error fetching patient details');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                setLoading(true);

                // Trim all string values to remove extra spaces at start or end
                const formattedValues = {
                    ...values,
                    first_name: values.first_name.trim(),
                    last_name: values.last_name.trim(),
                    email: values.email.trim(),
                    phone: values.phone ? values.phone.trim() : null,  // Phone input
                    date_of_birth: values.date_of_birth ? values.date_of_birth.format('YYYY-MM-DD') : null,
                    quick_patient_notes: values.quick_patient_notes ? values.quick_patient_notes.trim() : null, // New Field
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/${patientId}/update`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${auth.user['access_token']}`,
                        },
                        method: 'PATCH',
                        body: JSON.stringify(formattedValues),
                    });

                    if (response.ok) {
                        message.success('Patient details updated successfully');
                        setIsEditable(false);
                        setOpen(false);
                        setUpdated && setUpdated(true);
                    } else {
                        const errorData = await response.json();
                        message.error(errorData.detail?.message || 'Failed to update patient details');
                    }
                } catch (error) {
                    message.error('Failed to update patient');
                    console.error('Error submitting patient data:', error);
                } finally {
                    setLoading(false);
                }
            })
            .catch((info) => {
                console.error('Validate Failed:', info);
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setOpen(false);
        setIsEditable(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Edit Patient Details"
            open={open}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            footer={[
                <Button key="edit" onClick={handleEdit} disabled={isEditable}>
                    Edit
                </Button>,
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk} disabled={!isEditable}>
                    Save
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" name="edit_patient_form">
                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true, message: 'Please input the first name!' }]}
                >
                    <Input placeholder="Enter first name" disabled={!isEditable} />
                </Form.Item>

                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please input the last name!' }]}
                >
                    <Input placeholder="Enter last name" disabled={!isEditable} />
                </Form.Item>

                <Form.Item
                    name="date_of_birth"
                    label="Date of Birth"
                    // rules={[{ required: true, message: 'Please select the date of birth!' }]}
                >
                    <DatePicker style={{ width: '100%' }} disabled={!isEditable} placeholder="YYYY-MM-DD" />
                </Form.Item>

                <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: 'Please select the gender!' }]}
                >
                    <Radio.Group disabled={!isEditable}>
                        <Radio value="M">Male</Radio>
                        <Radio value="F">Female</Radio>
                        <Radio value="O">Other</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                >
                    <Input placeholder="Enter email" disabled={!isEditable} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Phone"
                    // rules={[{ required: true, message: 'Please input the phone number!' }]}
                >
                    <PhoneInput
                        country={'us'}
                        value={form.getFieldValue('phone')}
                        onChange={(value) => form.setFieldsValue({ phone: value.trim() })}  // Trim phone value
                        disabled={!isEditable}
                        inputStyle={{ width: '100%' }}
                    />
                </Form.Item>

                {/* New Quick Patient Notes Field */}
                <Form.Item
                    name="quick_patient_notes"
                    label="Quick Patient Notes"
                    // No rules since it's optional
                >
                    <Input.TextArea 
                        placeholder="Enter any quick notes about the patient" 
                        rows={4} 
                        allowClear 
                        disabled={!isEditable} 
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditPatientModal;
