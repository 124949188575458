// PatientDetails.jsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Space,
  Modal,
  message,
  Tag,
  Row,
  Col,
  Typography,
  Spin,
  List,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  UploadOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import EditPatientModal from './EditPatientModal/EditPatientModal';
import AddPatientModal from './AddPatientModal/AddPatientModal';
import BulkUploadModal from './BulkUploadModal/BulkUploadModal';
import PatientFileUploaderModal from './PatientFileUploaderModal/PatientFileUploaderModal';
import IconButtonGroup from '../Synopsis/IconButtonGroup/IconButtonGroup';
import ThemeContext from '../../context/ThemeContext';
import { useAuth } from '../../auth/AuthContext';
import StickyChat from '../Synopsis/StickyChat/StickyChat';
import debounce from 'lodash.debounce';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const { confirm } = Modal;
const { Title, Paragraph } = Typography;

const PatientDetails = () => {
  const { themeMode } = useContext(ThemeContext);
  const auth = useAuth();
  const [patientsData, setPatientsData] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Sorting state
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  // Filtering state
  const [filters, setFilters] = useState({
    first_name: [],
    last_name: [],
    gender: [],
    email: [],
    phone: [],
    date_of_birth: [],
  });

  // Unique filter values
  const [uniqueValues, setUniqueValues] = useState({
    first_name: [],
    last_name: [],
    gender: [],
    email: [],
    phone: [],
    date_of_birth: [],
  });

  // Synopsis Modal state
  const [isSynopsisModalOpen, setIsSynopsisModalOpen] = useState(false);
  const [selectedSynopsis, setSelectedSynopsis] = useState('');
  const [synopsisLoading, setSynopsisLoading] = useState(false);

  // Uploaded Files Modal state
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientFiles, setSelectedPatientFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  
  // Loader state for file deletion
  const [deletingFileName, setDeletingFileName] = useState(null);

  // Ref to hold active polling intervals (keyed by patientId)
  const pollingIntervals = useRef({});

  // Polling function: checks the patient’s processing status every 5 seconds
  const pollPatient = (patientId) => {
    // Do not start another poll if one is already running for this patient
    if (pollingIntervals.current[patientId]) {
      return;
    }

    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/${patientId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          // If processed, update the patient record and clear the polling interval
          if (data.is_pcr_processed === true) {
            setPatientsData((prevPatients) =>
              prevPatients.map((patient) =>
                patient._id === patientId
                  ? { ...patient, is_pcr_processed: true, pcr_synopsis: data.pcr_synopsis }
                  : patient
              )
            );
            clearInterval(intervalId);
            delete pollingIntervals.current[patientId];
          } else {
            // Otherwise, update the flag to false (so the UI shows the loader)
            setPatientsData((prevPatients) =>
              prevPatients.map((patient) =>
                patient._id === patientId
                  ? { ...patient, is_pcr_processed: false }
                  : patient
              )
            );
          }
        }
      } catch (error) {
        console.error('Error polling patient status:', error);
      }
    }, 5000);

    pollingIntervals.current[patientId] = intervalId;
  };

  // Clean up all polling intervals on component unmount
  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach(clearInterval);
    };
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          }
        });
        if (response.ok) {
          const data = await response.json();
          const expiryDate = new Date(data.subscription_expiry_date);
          const hasExpired = expiryDate < new Date();
          if (data.subscription_status && (data.subscription_status === 'active' || (data.subscription_status === 'canceled' && !hasExpired))){
            setHasActiveSubscription(true)
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [auth.user['access_token']]);

  // Edit Modal state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);

  // File Uploader Modal state
  const [isFileUploaderModalOpen, setIsFileUploaderModalOpen] = useState(false);
  const [selectedPatientForUpload, setSelectedPatientForUpload] = useState(null);

  const showBulkUploadModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  const handleBulkUploadCancel = () => {
    setIsBulkUploadModalOpen(false);
  };

  // Debounced fetch to prevent excessive API calls
  const debouncedFetch = debounce(() => {
    fetchPatients();
  }, 300);

  // Convert Markdown to sanitized HTML
  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  // Fetch unique filter values
  const fetchUniqueValues = async () => {
    try {
      const columnsToFetch = [
        'first_name',
        'last_name',
        'date_of_birth',
        'gender',
        'email',
        'phone',
      ];
      const fetchPromises = columnsToFetch.map((column) =>
        fetch(`${process.env.REACT_APP_API_URL}/patients/unique/${column}`, {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch unique values for ${column}`);
          }
          return response.json();
        })
      );

      const results = await Promise.all(fetchPromises);

      const newUniqueValues = {};
      columnsToFetch.forEach((column, index) => {
        newUniqueValues[column] = results[index].unique_values;
      });

      setUniqueValues((prev) => ({
        ...prev,
        ...newUniqueValues,
      }));
    } catch (error) {
      console.error('Error fetching unique values:', error);
      message.error('An error occurred while fetching unique filter values.');
    }
  };

  // Effect to fetch patients data
  useEffect(() => {
    debouncedFetch();

    // Cleanup debounce on unmount
    return () => {
      debouncedFetch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortField, sortOrder, filters]);

  // Effect to fetch unique values on component mount
  useEffect(() => {
    fetchUniqueValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.append('skip', (currentPage - 1) * pageSize);
      params.append('limit', pageSize);

      // Add sorting parameters if any
      if (sortField && sortOrder) {
        params.append('sort_field', sortField);
        params.append('sort_order', sortOrder === 'ascend' ? 'asc' : 'desc');
      }

      // Add filtering parameters if any
      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key].length > 0) {
          if (key === 'date_of_birth') {
            filters[key].forEach((date) => {
              params.append('date_of_birth', date);
            });
          } else {
            filters[key].forEach((value) => {
              params.append(key, value);
            });
          }
        }
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const { data, count } = result.patients;
        setPatientsData(data);
        setTotalPatients(count);

        // For each patient still processing the synopsis, start polling.
        data.forEach((patient) => {
          if (patient.is_pcr_processed === false) {
            pollPatient(patient._id);
          }
        });
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to fetch patients data');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
      message.error('An error occurred while fetching patients data');
    } finally {
      setLoading(false);
      setUpdated(false);
    }
  };


  const handleTableChange = (pagination, tableFilters, sorter) => {
    // Handle pagination
    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize);
      setCurrentPage(1); // Reset to first page when page size changes
    }

    // Handle sorting
    if (sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order);
    } else {
      setSortField(null);
      setSortOrder(null);
    }

    // Handle filters
    const updatedFilters = { ...filters };

    // Extract filters from tableFilters
    Object.keys(updatedFilters).forEach((key) => {
      if (
        key === 'gender' ||
        key === 'first_name' ||
        key === 'last_name' ||
        key === 'email' ||
        key === 'phone' ||
        key === 'date_of_birth'
      ) {
        updatedFilters[key] = tableFilters[key] ? tableFilters[key] : [];
      }
    });

    setFilters(updatedFilters);
  };

  const showDeleteConfirm = (patientId, patientName) => {
    confirm({
      title:
        'Are you sure you want to delete this patient? This action will permanently delete the patient data and cannot be undone.',
      content: `Patient Name: ${patientName}`,
      okText: 'Confirm Permanent Deletion',
      okType: 'primary',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk() {
        deletePatient(patientId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const deletePatient = async (patientId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients/${patientId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.message) {
          message.success(result.message);
          fetchPatients(); // Refresh data
          fetchUniqueValues(); // Refresh unique filter values
        } else {
          message.success('Patient deleted successfully');
          fetchPatients(); // Refresh data
          fetchUniqueValues(); // Refresh unique filter values
        }
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to delete patient');
      }
    } catch (error) {
      console.error('Error deleting patient:', error);
      message.error('An error occurred while deleting the patient');
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (patientId) => {
    setSelectedPatientId(patientId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedPatientId(null);
    setIsEditModalOpen(false);
    fetchPatients(); // Refresh data after editing
    fetchUniqueValues(); // Refresh unique filter values
  };

  // File Uploader Modal functions
  const openFileUploaderModal = (patientId) => {
    setSelectedPatientForUpload(patientId);
    setIsFileUploaderModalOpen(true);
  };

  const closeFileUploaderModal = () => {
    setSelectedPatientForUpload(null);
    setIsFileUploaderModalOpen(false);
  };

  // Synopsis Modal functions
  const openSynopsisModal = async (patientId) => {
    setIsSynopsisModalOpen(true);
    setSelectedSynopsis(''); // Reset previous synopsis
    setSynopsisLoading(true); // Set loading state

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/pcr/${patientId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setSelectedSynopsis(data.pcr_synopsis || 'No synopsis available for this patient.');
      } else {
        const errorData = await response.json();
        console.error('Error Data:', errorData);
        message.error(errorData.detail || 'Failed to fetch patient synopsis.');
        setSelectedSynopsis('Failed to fetch synopsis.');
      }
    } catch (error) {
      console.error('Error fetching patient synopsis:', error);
      message.error('An error occurred while fetching patient synopsis.');
      setSelectedSynopsis('Error fetching synopsis.');
    } finally {
      setSynopsisLoading(false); // Reset loading state
    }
  };

  const closeSynopsisModal = () => {
    setSelectedSynopsis('');
    setIsSynopsisModalOpen(false);
  };

  // Files Modal functions
  const openFilesModal = async (patientId) => {
    setIsFilesModalOpen(true);
    setSelectedPatientFiles([]); // Reset previous files
    setFilesLoading(true); // Set loading state

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/${patientId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setSelectedPatientFiles(data.pcr_documents_metadata || []);
        setSelectedPatient(data._id);
      } else {
        const errorData = await response.json();
        console.error('Error Data:', errorData);
        message.error(errorData.detail || 'Failed to fetch uploaded files.');
        setSelectedPatientFiles([]);
        setSelectedPatient(null);
      }
    } catch (error) {
      console.error('Error fetching patient files:', error);
      message.error('An error occurred while fetching patient files.');
      setSelectedPatientFiles([]);
      setSelectedPatient(null);
    } finally {
      setFilesLoading(false); // Reset loading state
    }
  };

  const closeFilesModal = () => {
    setSelectedPatientFiles([]);
    setSelectedPatient(null);
    setIsFilesModalOpen(false);
  };

  const deleteFile = async (patientId, fileName) => {
    setDeletingFileName(fileName); // Start loader for this file
    try {
      const encodedFileName = encodeURIComponent(fileName);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients/upload/pcr/${patientId}?file_names=${encodedFileName}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'DELETE',
        }
      );

      if (response.ok) {
        const result = await response.json();
        message.success(result.message || 'File deleted successfully.');
        // Refresh the files list
        openFilesModal(patientId);
        // Start polling to check if the PCR is processed after deletion
        pollPatient(patientId);
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to delete the file.');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('An error occurred while deleting the file.');
    } finally {
      setDeletingFileName(null); // Stop loader
    }
  };

  useEffect(() => {
    if (updated) {
      fetchPatients();
      fetchUniqueValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  // Calculate total width of columns
  const totalWidth = 150 + 150 + 150 + 100 + 250 + 150 + 150 + 150; // Adjusted sum to accommodate new Synopsis column width

  // Define table columns with sorting and multi-select filtering
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.first_name.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.first_name.length > 0 ? filters.first_name : null,
      onFilter: (value, record) => record.first_name === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.last_name.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.last_name.length > 0 ? filters.last_name : null,
      onFilter: (value, record) => record.last_name === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.date_of_birth.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.date_of_birth.length > 0 ? filters.date_of_birth : null,
      onFilter: (value, record) => record.date_of_birth === value,
      render: (text) =>
        text ? new Date(text).toLocaleDateString() : '',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.gender.map((value) => {
        let text = value;
        switch (value) {
          case 'M':
            text = 'Male';
            break;
          case 'F':
            text = 'Female';
            break;
          case 'O':
            text = 'Other';
            break;
          default:
            break;
        }
        return { text, value };
      }),
      filteredValue:
        filters.gender.length > 0 ? filters.gender : null,
      onFilter: (value, record) => record.gender === value,
      render: (gender) => {
        let color;
        let text;
        switch (gender) {
          case 'M':
            color = 'blue';
            text = 'Male';
            break;
          case 'F':
            color = 'pink';
            text = 'Female';
            break;
          case 'O':
            color = 'green';
            text = 'Other';
            break;
          default:
            color = 'default';
            text = gender;
        }
        return <Tag color={color}>{text}</Tag>;
      },
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.email.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.email.length > 0 ? filters.email : null,
      onFilter: (value, record) => record.email === value,
      render: (email) => (
        <span style={{ color: '#64B441' }}>{email}</span>
      ),
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.phone.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.phone.length > 0 ? filters.phone : null,
      onFilter: (value, record) => record.phone === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Synopsis',
      dataIndex: 'pcr_synopsis',
      key: 'pcr_synopsis',
      render: (_, record) => {
        // If the patient’s PCR is not yet processed, show a loader.
        if (record.is_pcr_processed === false) {
          return <Spin tip="Processing..." />;
        }
        // Once processed and a synopsis is available, show the View Synopsis button.
        if (record.is_pcr_processed === true && record.pcr_synopsis && record.pcr_synopsis.trim() !== '') {
          return (
            <Button
              type="link"
              onClick={() => openSynopsisModal(record._id)}
              style={{ padding: 0 }}
            >
              View Synopsis
            </Button>
          );
        }
        // Otherwise, show Not Available.
        return <span>Not Available</span>;
      },
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => openEditModal(record._id)}
            disabled={!hasActiveSubscription}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() =>
              showDeleteConfirm(
                record._id,
                `${record.first_name} ${record.last_name}`
              )
            }
            disabled={!hasActiveSubscription}
          />
          {/* Upload Button */}
          <Button
            icon={<UploadOutlined />}
            onClick={() => openFileUploaderModal(record._id)}
            disabled={!hasActiveSubscription}
          />
          {/* Info Button */}
          <Button
            icon={<InfoCircleOutlined />}
            onClick={() => openFilesModal(record._id)}
            disabled={!hasActiveSubscription}
            type="link"
            style={{ padding: 0 }}
          />
        </Space>
      ),
      width: 150,
      fixed: 'right',
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              to="/synopsis"
              style={{
                fontSize: '20px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <LeftOutlined style={{ marginRight: '8px' }} />
            </Link>
            <Title level={3} style={{ marginTop: '10px' }}>
              Patient Management
            </Title>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Space
            style={{
              marginBottom: 16,
              float: 'right',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsAddModalOpen(true)}
              style={{ marginRight: '15px' }}
              disabled={!hasActiveSubscription}
            >
              Add a Patient
            </Button>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={showBulkUploadModal}
              disabled={!hasActiveSubscription}
            >
              Bulk Upload Patient
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={patientsData}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalPatients,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showQuickJumper: true,
          // Center the pagination
          position: ['bottomCenter'],
        }}
        style={{ width: '100%' }}
        onChange={handleTableChange}
        scroll={{ x: totalWidth }}
      />

      {/* Edit Patient Modal */}
      {isEditModalOpen && (
        <EditPatientModal
          open={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          patientId={selectedPatientId}
          setUpdated={setUpdated}
        />
      )}

      {/* Add Patient Modal */}
      {isAddModalOpen && (
        <AddPatientModal
          open={isAddModalOpen}
          setOpen={setIsAddModalOpen}
          setUpdated={setUpdated}
        />
      )}

      {/* Bulk Upload Modal */}
      <BulkUploadModal
        visible={isBulkUploadModalOpen}
        onCancel={handleBulkUploadCancel}
        onUploadSuccess={() => setUpdated(true)}
        apiUrl={process.env.REACT_APP_API_URL}
        authToken={auth.user['access_token']}
        templateDownloadUrl={`${process.env.REACT_APP_API_URL}/patients/download/bulk_upload_test_data`}
      />

      {/* File Uploader Modal */}
      <PatientFileUploaderModal
        visible={isFileUploaderModalOpen}
        onCancel={closeFileUploaderModal}
        onUploadSuccess={() => {
          setUpdated(true);
          // Start polling for this patient once files have been uploaded
          pollPatient(selectedPatientForUpload);
        }}
        patientId={selectedPatientForUpload}
        apiUrl={process.env.REACT_APP_API_URL}
        authToken={auth.user['access_token']}
        themeMode={themeMode}
      />

      {/* Synopsis Modal */}
      <Modal
        open={isSynopsisModalOpen}
        title="Patient Synopsis"
        onCancel={closeSynopsisModal}
        footer={[
          <Button key="close" onClick={closeSynopsisModal}>
            Close
          </Button>,
        ]}
        centered
        className={themeMode === 'dark' ? 'dark-modal' : 'light-modal'}
        width={800}
      >
        {synopsisLoading ? (
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <Spin tip="Loading Synopsis..." />
          </div>
        ) : (
          <>
            <div style={{ textAlign: "right" }}>
              <IconButtonGroup
                text={selectedSynopsis}
                feature={{
                  edit: false,
                  copy: true,
                  download: false,
                  email: false,
                  fullscreen: false,
                }}
                contentType={'summary'}
              />
            </div>
            <div
              className="synopsis-content"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(selectedSynopsis),
              }}
            />
          </>
        )}
      </Modal>

      {/* Uploaded Files Modal */}
      <Modal
        open={isFilesModalOpen}
        title="Uploaded Files"
        onCancel={closeFilesModal}
        footer={[
          <Button key="close" onClick={closeFilesModal}>
            Close
          </Button>,
        ]}
        centered
        className={themeMode === 'dark' ? 'dark-modal' : 'light-modal'}
        width={600}
      >
        {filesLoading ? (
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <Spin tip="Loading Files..." />
          </div>
        ) : (
          selectedPatientFiles.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={selectedPatientFiles}
              renderItem={(file) => (
                <List.Item
                  actions={[
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        confirm({
                          title: 'Are you sure you want to delete this file?',
                          content: `File Name: ${file.original_file_name}`,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk() {
                            deleteFile(selectedPatient, file.file_name);
                          },
                        });
                      }}
                      loading={deletingFileName === file.file_name}
                    >
                      <DeleteOutlined />
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      file.url ? (
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.original_file_name}
                        </a>
                      ) : (
                        <>
                          <p style={{ marginBottom: 0 }}>{file.original_file_name}</p>
                          <small style={{ color: "#aaa" }}>{file.upload_time}</small>
                        </>
                      )
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <Paragraph>No uploaded files for this patient.</Paragraph>
          )
        )}
      </Modal>
      
      {/* Sticky Chat Component */}
      <StickyChat 
        title="Synopsis Chatbot" 
        fileId={"00"} 
        type={"synopsis"} 
      />
    </div>
  );
};

export default PatientDetails;
