import { AudioOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, List, Modal, Select, Space, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../auth/AuthContext';
import '../ChatModal/ChatModal.css';
import botAvatar from '../../../../assets/images/bot-avatar.png';
import IconButtonGroup from '../../IconButtonGroup/IconButtonGroup';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// Speech recognition setup
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.continuous = false;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
}

const initialMessages = [
  { id: 1, content: "Hello, What can I do for you?", sender: 'bot' },
];

const ChatModalSynopsis = ({ isModalOpen, handleOk, handleCancel, title, fileId, type }) => {
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState('');
  const [recording, setRecording] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const auth = useAuth();
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {};

  // Fetch patients when component mounts
  useEffect(() => {
    fetchPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset chat when fileId changes.
  useEffect(() => {
    setMessages(initialMessages);
    setNewMessage('');
    setRecording(false);
  }, [fileId]);

  // Scroll to the last message
  useEffect(() => {
    const scrollToBottom = () => {
      const lastMessageElem = document.querySelector('.chat-list .ant-list-item:last-child');
      if (lastMessageElem) {
        lastMessageElem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    };

    scrollToBottom();
  }, [messages]);

  // Speech recognition handlers
  useEffect(() => {
    if (!recognition) return;

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      setNewMessage(transcript);
      setRecording(false);
    };

    recognition.onend = () => {
      setRecording(false);
    };

    recognition.onerror = (event) => {
      console.error('SpeechRecognition error:', event.error);
      setRecording(false);
    };
  }, []);

  const handleRecord = () => {
    if (!SpeechRecognition) {
      console.error('SpeechRecognition is not supported in this browser.');
      return;
    }

    if (recording) {
      recognition.stop();
      setRecording(false);
    } else {
      recognition.start();
      setRecording(true);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  // When a patient is selected or cleared, handle accordingly.
  const handlePatientChange = (value) => {
    if (!value && selectedPatient) {
      // If the user clears the selection, ask for confirmation.
      Modal.confirm({
        title: 'Clear Chat',
        content: 'Are you sure you want to clear the chat?',
        onOk() {
          setSelectedPatient(null);
          setMessages(initialMessages);
          setNewMessage('');
        },
      });
    } else {
      // If selecting/changing the patient, clear the chat as well.
      if (selectedPatient && selectedPatient !== value) {
        Modal.confirm({
          title: 'Clear Chat',
          content: 'Changing patient will clear the current chat. Proceed?',
          onOk() {
            setSelectedPatient(value);
            setMessages(initialMessages);
            setNewMessage('');
          },
        });
      } else {
        setSelectedPatient(value);
      }
    }
  };

  // Clear Chat button handler
  const handleClearChat = () => {
    Modal.confirm({
      title: 'Clear Chat',
      content: 'Are you sure you want to clear the chat?',
      onOk() {
        setMessages(initialMessages);
        setNewMessage('');
      },
    });
  };

  const handleSendMessage = async () => {
    if (!selectedPatient) {
      message.error('Please select a patient first.');
      return;
    }
    if (!newMessage.trim()) return;

    // User message
    const nextYouMessage = {
      id: messages.length + 1,
      content: newMessage,
      sender: 'you',
    };

    // Temporary loading message for bot
    const loadingMessage = {
      id: messages.length + 2,
      content: "Loading bot's response...",
      sender: 'bot',
    };

    // Update state with user message and temporary loading message
    setMessages((msgs) => [...msgs, nextYouMessage, loadingMessage]);
    setNewMessage('');
    try {
      const botResponse = await fetchBotResponse(newMessage);

      // Replace the loading message with the actual bot response
      setMessages((msgs) =>
        msgs.map((message) =>
          message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        )
      );
    } catch (error) {
      console.error("Fetching bot's response failed:", error);
      // Update loading message with error message
      setMessages((msgs) =>
        msgs.map((message) =>
          message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        )
      );
    }
  };

  // Build the GET URL using the selected patient id.
  const fetchBotResponse = async (query) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/chatbot/qdrant/${selectedPatient}/query/pcr?question=${encodeURIComponent(query)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${auth.user['access_token']}`,
        }
      });
      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          return data.error;
        }
        return data.answer;
      } else {
        return "Error fetching response";
      }
    } catch (err) {
      return err.toString();
    }
  };

  // Fetch patients using your provided snippet.
  const fetchPatients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        const data = result.patients.data;
  
        // Build a frequency map for full names (case-insensitive)
        const frequencyMap = data.reduce((acc, item) => {
          const fullName = `${item.first_name.trim()} ${item.last_name.trim()}`.toLowerCase();
          acc[fullName] = (acc[fullName] || 0) + 1;
          return acc;
        }, {});
  
        // Map each patient to an option.
        // Append the email only if more than one patient has the same full name.
        const options = data.map((item) => {
          const fullName = `${item.first_name.trim()} ${item.last_name.trim()}`;
          const fullNameLower = fullName.toLowerCase();
          if (frequencyMap[fullNameLower] > 1) {
            return {
              label: `${fullName} (${item.email})`,
              value: item._id,
              searchText: `${fullName} ${item.email}`.toLowerCase(),
            };
          } else {
            return {
              label: fullName,
              value: item._id,
              searchText: fullNameLower,
            };
          }
        });
  
        setPatients(options);
      } else {
        message.error('Failed to fetch patient information');
      }
    } catch (error) {
      console.error('Error fetching patient information', error);
      message.error('Error fetching patient information');
    }
    setPageLoading(false);
  };
  

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={400}
      maskClosable={false}
      footer={null}
      style={{ right: 80, position: 'fixed', margin: 0, top: 200 }}
    >
      {pageLoading ? (
        <Spin tip="Loading patients..." />
      ) : (
        <>
          {/* Top section with Patient Dropdown and Clear Chat Button */}
          <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Select
              placeholder="Select a patient"
              style={{ width: 300 }}
              allowClear
              showSearch
              onChange={handlePatientChange}
              // Custom filter to search by name and email.
              filterOption={(input, option) =>
                option?.searchText.indexOf(input.toLowerCase()) >= 0
              }
              options={patients}
              value={selectedPatient}
            />
            <Button onClick={handleClearChat} disabled={messages.length === 1} danger>
            <DeleteOutlined />
            </Button>
          </div>

          <div className="chat-container">
            <List
              className="chat-list"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  className={`chat-message ${item.sender}`}
                  style={{ padding: "10px", borderRadius: "10px" }}
                >
                  {item.sender === 'bot' && (
                    <Avatar
                      src={botAvatar}
                      style={{ backgroundColor: "#000000", padding: "5px" }}
                      className="avatar-left"
                    />
                  )}
                  <List.Item.Meta
                    title={item.sender === 'you' ? 'You' : 'Synopsis AI'}
                    description={
                      item.content === "Loading bot's response..." ? (
                        <Spin />
                      ) : item.sender === 'bot' ? (
                        <div>
                          {item.id !== 1 && (
                            <div style={{ textAlign: "right" }}>
                              <IconButtonGroup
                                text={item.content}
                                feature={{ edit: false, copy: true, download: false, email: false }}
                                fileId={fileId}
                              />
                            </div>
                          )}
                          <div
                            className="message-content"
                            dangerouslySetInnerHTML={{ __html: markdownToHtml(item.content) }}
                          />
                        </div>
                      ) : (
                        item.content
                      )
                    }
                  />

                  {item.sender === 'you' && (
                    <Avatar className="avatar-right" style={{ backgroundColor: "#1677FF" }}>
                      {`${user.first_name[0]}${user.last_name[0]}`}
                    </Avatar>
                  )}
                </List.Item>
              )}
            />
          </div>
          <Space.Compact style={{ width: '100%', marginTop: "20px" }}>
            <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} />
            <Input
              value={newMessage}
              onChange={handleInputChange}
              onPressEnter={handleSendMessage}
              placeholder={selectedPatient ? "Ask your query..." : "Select a patient first"}
              disabled={!selectedPatient}
            />
            <Button type="primary" onClick={handleSendMessage} icon={<SendOutlined />} disabled={!selectedPatient} />
          </Space.Compact>
        </>
      )}
    </Modal>
  );
};

export default ChatModalSynopsis;
