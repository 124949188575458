// StickyChat.jsx
import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import ChatModal from './ChatModal/ChatModal';
import ChatModalSynopsis from './ChatModalSynopsis/ChatModalSynopsis';

const StickyChat = ({ title, fileId, patientId, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<MessageOutlined style={{ fontSize: '20px' }} />}
        shape="circle"
        style={type === 'summary'? {
            position: "fixed",
            bottom: 20,
            right: 70,
            height: "40px",
            width: "40px"
        }:{
            position: "fixed",
            bottom: 20,
            right: 70,
            height: "40px",
            width: "40px",
            zIndex: 1001, 
          }
        }
        onClick={showModal}
      />
      {type === 'summary' ? (
        <ChatModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={title}
          fileId={fileId}
          patientId={patientId}
          type={type}
        />
      ) : (
        <ChatModalSynopsis
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={title}
          fileId={fileId}
          type={type}
        />
      )}
    </>
  );
};

export default StickyChat;
