// PatientFileUploaderModal.jsx
import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './PatientFileUploaderModal.css'; // Ensure you have the relevant CSS
import { useAuth } from '../../../auth/AuthContext';

const { Dragger } = Upload;

const PatientFileUploaderModal = ({
  visible,
  onCancel,
  onUploadSuccess,
  patientId,
  apiUrl,
  authToken,
  themeMode,
}) => {
  const auth = useAuth();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  // Handle file selection
  const handleUploadChange = ({ fileList }) => setFileList(fileList);

  // Handle the upload process
  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.warning('Please select at least one file to upload.');
      return;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    setUploading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/patients/upload/pcr/${patientId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        message.success('Files uploaded successfully. The synopsis will be generated shortly.', 7);
        setFileList([]);
        onUploadSuccess(); // Notify parent to refresh data if needed
        onCancel(); // Close the modal
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to upload files.');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      message.error('An error occurred while uploading files.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Upload Files"
      onCancel={onCancel}
      footer={null} // Remove default footer to implement custom buttons
      centered
      className={`patient-file-uploader-modal ${themeMode === 'dark' ? 'dark-modal' : 'light-modal'}`}
      width={500} // Adjust width as needed
      destroyOnClose
    >
      <div className="upload-container">
        <Dragger
          multiple
          fileList={fileList}
          onChange={handleUploadChange}
          beforeUpload={() => false} // Prevent automatic upload
          accept=".pdf,.docx,.png,.jpg,.jpeg, .csv, .xlsx, .json, .txt" // Specify accepted file types
          style={{ width: '100%', maxWidth: '400px' }} // Responsive width
          className="custom-dragger"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag files to this area to upload</p>
          <p className="ant-upload-hint">Support for multiple files. Accepted formats: PDF, DOCX, PNG, JPG, JPEG, CSV, XLSX, JSON and TXT.</p>
        </Dragger>
      </div>
      <div className="upload-buttons">
        <Button type="primary" onClick={handleUpload} loading={uploading} style={{ marginRight: '10px' }}>
          {uploading ? 'Uploading' : 'Upload'}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </Modal>
  );
};

PatientFileUploaderModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  apiUrl: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  themeMode: PropTypes.string.isRequired,
};

PatientFileUploaderModal.defaultProps = {
  patientId: null,
};

export default PatientFileUploaderModal;
