// AudioRecorder.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  message,
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Divider,
  Badge,
  Tooltip,
  Progress,
  Space,
  Collapse,
  Modal
} from 'antd';
import {
  AudioOutlined,
  DeleteOutlined,
  UserAddOutlined,
  XFilled,
  LoadingOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import AddPatientModal from '../../../PatientDetails/AddPatientModal/AddPatientModal';
import { useAuth } from '../../../../auth/AuthContext';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const AudioRecorder = ({
  setOpen,
  setSelectedId,
  fetchData,
  setNewSubmission,
  setSelectedLanguage,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  // ----- Existing State Variables -----
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordingState, setRecordingState] = useState('inactive'); // 'inactive', 'recording', 'paused'
  const [audioURL, setAudioURL] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientId, setPatientId] = useState('');
  const [patientName, setPatientName] = useState(null);
  const [language, setLanguage] = useState('english');
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [blobName, setBlobName] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [micInfo, setMicInfo] = useState(''); // Microphone Information
  const [bandwidthStatus, setBandwidthStatus] = useState('unknown'); // 'low', 'medium', 'high', 'offline', 'unknown'
  const [downloadSpeed, setDownloadSpeed] = useState(null); // in Mbps
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Online Status
  const [deleteLoading, setDeleteLoading] = useState(false);
  const audioChunksRef = useRef([]);

  // Recording duration state
  const [recordingDuration, setRecordingDuration] = useState(0); // in seconds
  const recordingIntervalRef = useRef(null);
  const [autoStopNote, setAutoStopNote] = useState('');

  // Upload queue state
  const chunkUploadQueue = useRef([]);
  const isUploadingRef = useRef(false);

  // Synopsis state
  const [patientSynopsis, setPatientSynopsis] = useState('');
  const [isFetchingSynopsis, setIsFetchingSynopsis] = useState(false);

  // ----- NEW: Template Dropdown State (for default template selection) -----
  const [templateOptions, setTemplateOptions] = useState([]); // Grouped options for the Select
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // ----- Effects to fetch patients and templates -----
  useEffect(() => {
    setPageLoading(true);
    fetchPatients();
    fetchTemplatesForAudioRecorder(); // NEW helper to fetch templates and set default
  }, []);

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  // ----- NEW: Helper to Fetch Templates for AudioRecorder and Set Preferred Template -----
  const fetchTemplatesForAudioRecorder = async () => {
    try {
      // Fetch community/existing templates
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      let communityTemplates = [];
      if (response1.ok) {
        const result1 = await response1.json();
        const userDetails = localStorage.getItem("userDetails");
        const user = userDetails ? JSON.parse(userDetails) : {};
        communityTemplates = result1.data.filter(
          (r) =>
            r.community_template &&
            ((r.user_id === null && r.public) || (r.user_id === user.user_id && !r.public))
        );
      }
      // Fetch user-specific templates
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/templates/me`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      let customizedPredefinedTemplates = [];
      let yourCustomTemplates = [];
      if (response2.ok) {
        const result2 = await response2.json();
        const userDetails = localStorage.getItem("userDetails");
        const user = userDetails ? JSON.parse(userDetails) : {};
        const userId = user.user_id;
        customizedPredefinedTemplates = result2.data.filter(
          (r) => r.user_id === userId && r.type === 'customizedPredefined'
        );
        yourCustomTemplates = result2.data.filter(
          (r) => r.user_id === userId && r.type === 'customizedFromScratch'
        );
      }
      // Build grouped options for the Select
      const groups = [];
      if (communityTemplates.length > 0) {
        groups.push({
          label: 'Existing Templates',
          options: communityTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      if (customizedPredefinedTemplates.length > 0) {
        groups.push({
          label: 'Customized Predefined Templates',
          options: customizedPredefinedTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      if (yourCustomTemplates.length > 0) {
        groups.push({
          label: 'Your Custom Templates',
          options: yourCustomTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      setTemplateOptions(groups);
      
      // Now fetch the user details to see if there is a preferred template
      try {
        const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
          headers: { Authorization: `Bearer ${auth.user['access_token']}` },
          method: 'GET',
        });
        if (userResponse.ok) {
          const user = await userResponse.json();
          if (user.preferred_template_id) {
            setSelectedTemplate(user.preferred_template_id);
          } else if (groups.length > 0 && groups[0].options.length > 0) {
            setSelectedTemplate(groups[0].options[0].value);
          }
        } else if (groups.length > 0 && groups[0].options.length > 0) {
          setSelectedTemplate(groups[0].options[0].value);
        }
      } catch (err) {
        console.error("Error fetching user details:", err);
        if (groups.length > 0 && groups[0].options.length > 0) {
          setSelectedTemplate(groups[0].options[0].value);
        }
      }
    } catch (error) {
      console.error("Error fetching templates for AudioRecorder:", error);
      message.error("Error fetching templates.");
    }
  };

  // ----- Existing: Fetch Patients Function -----
  const fetchPatients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      if (response.ok) {
        const result = await response.json();
        const patientsData = result.patients.data;
  
        // Create a frequency map for full names (case-insensitive)
        const frequency = patientsData.reduce((acc, patient) => {
          const fullName = `${patient.first_name.trim()} ${patient.last_name.trim()}`.toLowerCase();
          acc[fullName] = (acc[fullName] || 0) + 1;
          return acc;
        }, {});
  
        // Map each patient to an option. If the full name appears more than once, include the email.
        const options = patientsData.map((patient) => {
          const fullName = `${patient.first_name.trim()} ${patient.last_name.trim()}`;
          const key = fullName.toLowerCase();
          if (frequency[key] > 1) {
            return { label: `${fullName} (${patient.email})`, value: patient._id };
          } else {
            return { label: fullName, value: patient._id };
          }
        });
  
        setPatients(options);
      } else {
        message.error('Failed to fetch patient information');
        setOpen(false);
      }
    } catch (error) {
      console.error('Error fetching patient information', error);
      message.error('Error fetching patient information');
      setOpen(false);
    }
    setPageLoading(false);
  };
  
  

  // ----- Existing: Microphone, Speed Test, Bandwidth Detection functions -----
  const getMicrophoneInfo = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      if (audioDevices.length > 0) {
        return audioDevices[0].label || 'Default Microphone';
      }
      return 'No Microphone Found';
    } catch (error) {
      console.error('Error fetching microphone information', error);
      return 'Unknown Microphone';
    }
  };

  const performSpeedTest = async () => {
    const imageUrl = 'https://via.placeholder.com/1000x1000.png';
    const startTime = new Date().getTime();
    try {
      const response = await fetch(imageUrl + `?cacheBuster=${startTime}`);
      const blob = await response.blob();
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = blob.size * 8;
      const speedBps = bitsLoaded / duration;
      const speedMbps = (speedBps / (1024 * 1024)).toFixed(2);
      setDownloadSpeed(speedMbps);
      return speedMbps;
    } catch (error) {
      console.error('Speed test failed:', error);
      setDownloadSpeed(null);
      return null;
    }
  };

  const detectBandwidth = async () => {
    if (!navigator.onLine) {
      setIsOnline(false);
      setBandwidthStatus('offline');
      return;
    }
    const handleOnline = () => {
      setIsOnline(true);
      message.info('You are back online.');
      detectBandwidth();
    };
    const handleOffline = () => {
      setIsOnline(false);
      setBandwidthStatus('offline');
      message.warning('You have lost internet connection.');
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    if ('connection' in navigator) {
      const connection =
        navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        const updateBandwidthStatus = () => {
          const effectiveType = connection.effectiveType;
          let status = 'unknown';
          switch (effectiveType) {
            case 'slow-2g':
            case '2g':
            case '3g':
              status = 'low';
              break;
            case '4g':
            case '5g':
              status = 'high';
              break;
            default:
              status = 'unknown';
          }
          setBandwidthStatus(status);
        };
        updateBandwidthStatus();
        connection.addEventListener('change', updateBandwidthStatus);
      }
    }
    const speedMbps = await performSpeedTest();
    if (speedMbps) {
      if (speedMbps < 1) {
        setBandwidthStatus('low');
      } else if (speedMbps >= 1 && speedMbps < 5) {
        setBandwidthStatus('medium');
      } else {
        setBandwidthStatus('high');
      }
    }
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  };

  // ----- Existing: Recording Controls Functions -----
  const startRecording = async () => {
    if (blobName) {
      message.error('Please submit or delete the existing recording before starting a new one.');
      return;
    }
    if (recordingState === 'recording') return;
    if (!patientName?.trim()) {
      message.error('Please select a patient first!');
      return;
    }
    let _blobName;
    if (!blobName) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('patient_id', patientId);
        formData.append('language', language);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v3/files/audio/upload`,
          {
            headers: { Authorization: `Bearer ${auth.user['access_token']}` },
            method: 'POST',
            body: formData,
          }
        );
        if (response.ok) {
          const result = await response.json();
          setBlobName(result.file_path);
          _blobName = result.file_path;
          setFileId(result.file_id);
        } else {
          message.error('Failed to start recording');
          return;
        }
      } catch (error) {
        console.error('Error start recording', error);
        message.error('Error start recording');
        return;
      } finally {
        setLoading(false);
      }
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      audioChunksRef.current = [];
      recorder.ondataavailable = (event) => {
        const chunk = event.data;
        chunkUploadQueue.current.push(chunk);
        processUploadQueue(_blobName);
        audioChunksRef.current.push(chunk);
      };
      recorder.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        setAudioBlob(blob);
      };
      recorder.start(1000);
      setRecordingState('recording');
      message.info('Recording started!');
      setRecordingDuration(0);
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);
      const deviceInfo = await getMicrophoneInfo();
      setMicInfo(deviceInfo);
      detectBandwidth();
    } catch (error) {
      console.error('Error accessing the microphone', error);
      message.error('Failed to start recording');
    }
  };

  const processUploadQueue = async (_blobName) => {
    if (isUploadingRef.current) return;
    if (chunkUploadQueue.current.length === 0) return;
    isUploadingRef.current = true;
    const chunk = chunkUploadQueue.current.shift();
    try {
      const formData = new FormData();
      formData.append('blob_name', _blobName || blobName);
      formData.append('audio_chunk', chunk, 'chunk.webm');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/upload/chunk`,
        {
          headers: { Authorization: `Bearer ${auth.user['access_token']}` },
          method: 'POST',
          body: formData,
        }
      );
      if (response.ok) {
        console.log('Chunk uploaded successfully');
      } else {
        console.error('Failed to upload chunk');
        message.error('Failed to upload audio chunk');
      }
    } catch (error) {
      console.error('Error uploading chunk', error);
    } finally {
      isUploadingRef.current = false;
      processUploadQueue(_blobName);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
      setRecordingState('paused');
      message.info('Recording paused!');
      clearInterval(recordingIntervalRef.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume();
      setRecordingState('recording');
      message.info('Recording resumed!');
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setRecordingState('inactive');
      message.info('Recording stopped!');
      clearInterval(recordingIntervalRef.current);
    }
  };

  const deleteRecording = async () => {
    if (!fileId) {
      message.error('No interview to delete');
      return;
    }
    try {
      setDeleteLoading(true);
      const formData = new FormData();
      formData.append('file_id', fileId);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/upload/chunk/clear`,
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${auth.user['access_token']}` },
          body: formData,
        }
      );
      if (response.ok) {
        const result = await response.json();
        message.success('Recording deleted successfully');
        setMediaRecorder(null);
        setBlobName(null);
        setFileId(null);
        setRecordingState('inactive');
        audioChunksRef.current = [];
        setAudioURL(null);
        setAudioBlob(null);
        setMicInfo('');
        setBandwidthStatus('unknown');
        setDownloadSpeed(null);
        setAutoStopNote('');
        setRecordingDuration(0);
      } else {
        const errorResult = await response.json();
        message.error(errorResult.detail || 'Failed to delete recording');
      }
    } catch (error) {
      console.error('Error deleting recording:', error);
      message.error('Error deleting recording');
    } finally {
      setDeleteLoading(false);
    }
  };

  const submitRecording = async () => {
    if (!blobName) {
      message.error('No recording to submit');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file_id', fileId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/toggle_is_deleted`,
        {
          headers: { Authorization: `Bearer ${auth.user['access_token']}` },
          method: 'POST',
          body: formData,
        }
      );
      if (response.ok) {
        const result = await response.json();
        await fetchData();
        setNewSubmission(true);
        setSelectedId(fileId);
        setMediaRecorder(null);
        audioChunksRef.current = [];
        setBlobName(null);
        setFileId(null);
        setRecordingState('inactive');
        setAudioURL(null);
        setAudioBlob(null);
        setMicInfo('');
        setBandwidthStatus('unknown');
        setDownloadSpeed(null);
        setAutoStopNote('');
        setRecordingDuration(0);
        setOpen(false);
        message.success('Interview submitted successfully');
      } else {
        message.error('Failed to submit interview');
      }
    } catch (error) {
      console.error('Error submitting interview', error);
      message.error('Error submitting interview');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
      clearInterval(recordingIntervalRef.current);
    };
  }, [mediaRecorder]);

  useEffect(() => {
    if (recordingDuration >= 9000) {
      stopRecording();
      message.info(
        'Your interview has reached the maximum duration of 2.5 hours and has been stopped automatically.'
      );
      const utterance = new SpeechSynthesisUtterance(
        'You have reached the maximum duration of two and a half hours, and the recording has been stopped.'
      );
      window.speechSynthesis.speak(utterance);
      setAutoStopNote(
        'Recording automatically stopped after reaching maximum duration of 2.5 hours.'
      );
    }
  }, [recordingDuration]);

  const handleChangePatient = (value) => {
    setPatientId(value);
    const selectedOption = patients.find((option) => option.value === value);
    if (selectedOption) {
      setPatientName(selectedOption.label.split("(")[0].trim());
    } else {
      setPatientName(null);
    }
  };

  const renderBandwidthIndicator = () => {
    if (!isOnline) {
      return (
        <Tooltip title="You are offline">
          <Badge status="error" text="Offline" />
        </Tooltip>
      );
    }
    switch (bandwidthStatus) {
      case 'low':
        return (
          <Tooltip title="Low Internet Bandwidth">
            <Badge status="error" text="Low Bandwidth" />
          </Tooltip>
        );
      case 'medium':
        return (
          <Tooltip title="Medium Internet Bandwidth">
            <Badge status="warning" text="Medium Bandwidth" />
          </Tooltip>
        );
      case 'high':
        return (
          <Tooltip title="High Internet Bandwidth">
            <Badge status="success" text="High Bandwidth" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Bandwidth Status Unknown">
            <Badge status="default" text="Unknown" />
          </Tooltip>
        );
    }
  };

  const fetchPatientSynopsis = async (id) => {
    try {
      setIsFetchingSynopsis(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/pcr/${id}`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setPatientSynopsis(data.pcr_synopsis || 'No synopsis available for this patient.');
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to fetch patient synopsis.');
        setPatientSynopsis('Failed to fetch synopsis.');
      }
    } catch (error) {
      console.error('Error fetching patient synopsis:', error);
      message.error('An error occurred while fetching patient synopsis.');
      setPatientSynopsis('Error fetching synopsis.');
    } finally {
      setIsFetchingSynopsis(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientSynopsis(patientId);
    } else {
      setPatientSynopsis('');
    }
  }, [patientId]);

  return (
    <>
      {pageLoading ? (
        <div
          style={{
            height: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Row
          style={{
            height: '85vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Col xs={0} sm={0} md={0} lg={7}></Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <Button
                type="primary"
                shape="round"
                icon={<UserAddOutlined />}
                style={{ width: '100%', marginBottom: '20px' }}
                size="large"
                onClick={() => setPatientModalVisible(true)}
              >
                Add a New Patient
              </Button>
              <Divider>OR</Divider>
              {/* --- NEW: Template Dropdown for Default Template Selection --- */}
              <Typography.Title level={5}>Select Default Template</Typography.Title>
              <Select
                value={selectedTemplate}
                placeholder="Select a template"
                style={{ width: '100%', marginBottom: '15px' }}
                options={templateOptions}
                showSearch
                optionFilterProp="label"
                onChange={(value) => {
                  Modal.confirm({
                    title: 'Confirm Template Change',
                    content:
                      'Are you sure you want to make this template your default template?',
                    okText: 'Yes',
                    cancelText: 'No',
                    centered: true,
                    onOk: async () => {
                      setSelectedTemplate(value);
                      const formData = new FormData();
                      formData.append('template_id', value);
                      try {
                        const response = await fetch(
                          `${process.env.REACT_APP_API_URL}/templates/user/select`,
                          {
                            headers: {
                              Authorization: `Bearer ${auth.user['access_token']}`,
                            },
                            method: 'POST',
                            body: formData,
                          }
                        );
                        if (response.ok) {
                          message.success('Default template updated successfully');
                        } else {
                          message.error('Failed to update default template');
                        }
                      } catch (err) {
                        console.error('Error updating default template', err);
                        message.error('Error updating default template');
                      }
                    },
                    onCancel() {
                      // Do nothing if cancelled
                    },
                  });
                }}
                disabled={!!blobName || recordingState === 'recording' || recordingState === 'paused'}
              />
              <small>
                <i>
                  <b style={{ color: 'red' }}>Note: </b>To know more about the summary template please go to{' '}
                  <b
                    onClick={() => navigate('/configureTemplate')}
                    style={{
                      textDecoration: 'underline',
                      color: '#0074D9',
                      cursor: 'pointer',
                    }}
                  >
                    Configure Template
                  </b>
                  .
                </i>{' '}
              </small>
              {/* --- End New Template Dropdown --- */}

              {patientId && (
                <>
                  <Typography.Title level={5}>Select Interview Language</Typography.Title>
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Interview language"
                    optionFilterProp="label"
                    value={language}
                    onChange={(v) => {
                      setLanguage(v);
                      if (v === 'english') {
                        setSelectedLanguage('en');
                      } else if (v === 'hindi') {
                        setSelectedLanguage('hindi');
                      } else if (v === 'greek') {
                        setSelectedLanguage('el');
                      } else {
                        setSelectedLanguage('en');
                      }
                    }}
                    style={{ width: '100%', marginBottom: '15px' }}
                    options={[
                      { label: 'English', value: 'english' },
                      { label: 'Hindi', value: 'hindi' },
                      { label: 'Greek', value: 'greek' },
                    ]}
                    disabled={!!blobName || recordingState === 'recording' || recordingState === 'paused'}
                  />
                </>
              )}
              <Typography.Title level={5}>Select a Patient</Typography.Title>
              <Select
                allowClear
                showSearch
                placeholder="Select a patient"
                optionFilterProp="label"
                value={patientId}
                onChange={(v) => handleChangePatient(v)}
                style={{ width: '100%', marginBottom: '15px' }}
                options={patients}
                disabled={!!blobName || recordingState === 'recording' || recordingState === 'paused'}
              />
            </div>

            {recordingState !== 'inactive' && (
              <div style={{ marginTop: '30px' }}>
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                  <div>
                    <Text strong>Microphone:</Text> {micInfo || 'Not Selected'}
                  </div>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div>
                        <Text strong>Bandwidth Status:</Text> {renderBandwidthIndicator()}
                      </div>
                      {downloadSpeed && (
                        <div>
                          <Text strong>Download Speed:</Text> {downloadSpeed} Mbps
                          <Progress
                            percent={Math.min((downloadSpeed / 100) * 100, 100)}
                            size="small"
                            status="active"
                            style={{ marginTop: '5px' }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div>
                        <Text strong>Recording Duration:</Text>{' '}
                        {new Date(recordingDuration * 1000).toISOString().substr(11, 8)}
                      </div>
                    </Col>
                  </Row>
                </Space>
              </div>
            )}

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              {recordingState === 'inactive' && (
                <>
                  <Button
                    icon={<AudioOutlined style={{ fontSize: '20px' }} />}
                    onClick={startRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                      marginBottom: '15px',
                    }}
                    disabled={loading || blobName !== null}
                  />
                  <br />
                  <div>
                    <small>
                      <b style={{ color: 'red' }}>Note: </b>Recording will automatically stop after
                      reaching a maximum duration of <b>2.5 hours</b>.
                    </small>
                  </div>
                </>
              )}
              {recordingState === 'recording' && (
                <>
                  <Button
                    icon={<PauseCircleOutlined style={{ fontSize: '20px' }} />}
                    onClick={pauseRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                      backgroundColor: '#f9ae16',
                    }}
                  />
                  <Button
                    icon={<XFilled style={{ fontSize: '20px' }} />}
                    onClick={stopRecording}
                    type="primary"
                    danger
                    style={{
                      marginLeft: '10px',
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                </>
              )}
              {recordingState === 'paused' && (
                <>
                  <Button
                    icon={<AudioOutlined style={{ fontSize: '20px' }} />}
                    onClick={resumeRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                  <Button
                    icon={<XFilled style={{ fontSize: '20px' }} />}
                    onClick={stopRecording}
                    type="primary"
                    danger
                    style={{
                      marginLeft: '10px',
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                </>
              )}
            </div>

            {mediaRecorder && recordingState !== 'inactive' && (
              <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <LiveAudioVisualizer mediaRecorder={mediaRecorder} width={600} height={100} />
              </div>
            )}

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              {audioURL && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <audio src={audioURL} controls />
                  <Button
                    type="primary"
                    onClick={deleteRecording}
                    icon={deleteLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                    danger
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              )}
            </div>

            {blobName && recordingState === 'inactive' && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button type="primary" onClick={submitRecording} loading={loading}>
                  Submit Recording
                </Button>
              </div>
            )}

            {patientSynopsis && (
              <div style={{ marginTop: '20px' }}>
                {isFetchingSynopsis ? (
                  <Spin tip="Loading Synopsis..." />
                ) : (
                  <Collapse accordion>
                    <Panel header="Patient Synopsis" key="1">
                      <div
                        className="synopsis-content"
                        dangerouslySetInnerHTML={{ __html: markdownToHtml(patientSynopsis) }}
                      />
                    </Panel>
                  </Collapse>
                )}
              </div>
            )}
          </Col>
          <Col xs={0} sm={0} md={0} lg={7}></Col>
        </Row>
      )}
      <AddPatientModal
        open={patientModalVisible}
        setOpen={setPatientModalVisible}
        fetchPatients={fetchPatients}
        setPatientId={setPatientId}
        setPatientName={setPatientName}
      />
    </>
  );
};

export default AudioRecorder;
