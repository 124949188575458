// src/components/Synopsis.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Layout,
  Row,
  Col,
  Card,
  Button,
  Divider,
  List,
  Avatar,
  Typography,
  message,
  Spin,
  Badge,
  Collapse,
  Select,
  Tooltip,
  Empty,
  Input,
  DatePicker,
  Space,
  Modal,
  Form,
} from 'antd';
import {
  PlusOutlined,
  FileTextOutlined,
  FolderViewOutlined,
  ProfileOutlined,
  LoadingOutlined,
  RedoOutlined,
  DeleteOutlined,
  SearchOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import playIcon from '../../assets/images/play-icon.png';
import Waveform from './Waveform/Waveform';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { loadStripe } from '@stripe/stripe-js';
import IconButtonGroup from './IconButtonGroup/IconButtonGroup';
import RecordingModal from './RecordingModal/RecordingModal';
import { useAuth } from '../../auth/AuthContext';
import EditPatientModal from '../PatientDetails/EditPatientModal/EditPatientModal';
import AssessmentModal from './AssessmentModal/AssessmentModal';
import PatientNoteModal from './PatientNoteModal/PatientNoteModal';
import StickyChat from './StickyChat/StickyChat';
import ConfirmDeletePatientModal from './ConfirmDeletePatientModal/ConfirmDeletePatientModal';
import ThemeContext from '../../context/ThemeContext';
import '../../assets/css/ModalStyle.css';
import AddTemplateModal from '../TemplateSelector/CustomTemplate/YourCustomTemplate/AddTemplateModal/AddTemplateModal';
import PaymentModal from '../PaymentModal/PaymentModal'; // Ensure correct path

const { Content } = Layout;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Synopsis = () => {
  // Retrieve user details from localStorage
  const userDetails = localStorage.getItem('userDetails');
  const user = userDetails && JSON.parse(userDetails);
  const auth = useAuth();
  const { themeMode } = useContext(ThemeContext);

  // State Management
  const [modalVisible, setModalVisible] = useState(false);
  const [finalResponse, setFinalResponse] = useState(null);
  const [data, setData] = useState([]);
  const [patientInterview, setPatientInterview] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [patientDataLoading, setPatientDataLoading] = useState(null);
  const [patientInterviewLoading, setPatientInterviewLoading] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [versionLoading, setVersionLoading] = useState(false);
  const [versions, setVersions] = useState([]);
  const [editedSummary, setEditedSummary] = useState(null);
  const [aiGenerated, setAIGenerated] = useState(null);
  const [editSummaryLoading, setEditedSummaryLoading] = useState(false);
  const [activePanel, setActivePanel] = useState('01');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false);
  const [isPatientNoteModalOpen, setIsPatientNoteModalOpen] = useState(false);
  const [newSubmission, setNewSubmission] = useState(false);
  const [synopsisLoading, setSynopsisLoading] = useState(false);
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deletePatientEntryLoading, setDeletePatientEntryLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [templateOptions, setTemplateOptions] = useState([]); // Grouped options for the dropdown
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // Payment and Subscription States
  
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [addTemplateModalVisible, setAddTemplateModalVisible] = useState(false);
  const [addTemplateSaving, setAddTemplateSaving] = useState(false);
  const [addTemplateForm] = Form.useForm();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false); // New State
  const [paymentLoading, setPaymentLoading] = useState(false);
  // Below your other state declarations
  const [paymentAction, setPaymentAction] = useState('activate');

  // Handle Payment
  const handlePaymentAction = async () => {
    setPaymentLoading(true);
    try {
      if (paymentAction === 'reactivate') {
        // Reactivate subscription without Stripe checkout
        const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/reactivate-subscription`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const result = await response.json();
          message.success(result.message || 'Subscription reactivated successfully.');
          handlePaymentSuccess(); // Handle success (close modal, refresh data, etc.)
        } else {
          const errorData = await response.json();
          message.error(`Failed to reactivate subscription: ${errorData.detail || 'Unknown error'}`);
        }
      } else {
        // Handle 'activate' and 'renew' via Stripe checkout
        const price_id = `${process.env.REACT_APP_PLAN_ID}`; // Your Stripe price ID
        const plan_name = `${process.env.REACT_APP_PLAN_NAME}`; // Your plan name
  
        const formData = new FormData();
        formData.append('price_id', price_id);
        formData.append('plan_name', plan_name);
        formData.append('action', paymentAction); // Send the action to the backend if needed
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/create-checkout-session`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
            // Do not set 'Content-Type' when using FormData
          },
          body: formData,
        });
  
        if (response.ok) {
          const result = await response.json();
          const { sessionId } = result;
  
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            message.error(error.message);
          }
        } else {
          const errorData = await response.json();
          message.error(`Failed to create checkout session: ${errorData.detail || 'Unknown error'}`);
        }
      }
    } catch (error) {
      console.error('Error handling payment action:', error);
      message.error('Error handling payment action.');
    }
    setPaymentLoading(false);
  };
  
  const handlePaymentSuccess = () => {
    setIsPaymentModalOpen(false);
    // Refresh subscription status, or fetch user details
    checkSubscription(); 
    
    if (paymentAction === 'activate') {
      // If this was a first-time activation, you might open the AddTemplateModal
      setAddTemplateModalVisible(true);
    }
  };  

  // Handle Payment Modal Cancel
  const handlePaymentCancel = () => {
    // Prevent access if payment is mandatory
    if (user.first_login) {
      message.warning('Subscription is required to access all features.');
      setAddTemplateModalVisible(true)
    }
    setIsPaymentModalOpen(false);
  };

  // Handle Payment Status After Redirect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    const status = urlParams.get('status');

    if (sessionId && status === 'success') {
      // Payment was successful, verify the session
      verifySession(sessionId);
    } else if (status === 'cancelled') {
      message.error('Payment was not successful. Please subscribe to continue using all features.');
      setIsPaymentModalOpen(true); // Re-open payment modal if payment is mandatory
    } else {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            const expiryDate = new Date(data.subscription_expiry_date);
            const hasExpired = expiryDate < new Date();
            if (data.subscription_status){
              if(data.subscription_status !== 'active' && !(data.subscription_status === 'canceled' && !hasExpired)) {
                if (data.first_login){
                  setIsPaymentModalOpen(true)
                } else {
                  message.warning("You don't have an active subscription. Please renew/reactivate your subscription to access the all features.")
                }
              } else {
                setIsPaymentModalOpen(false)
                setHasActiveSubscription(true)
                if (data.first_login){
                  setAddTemplateModalVisible(true)
                }
              }
            } else {
              setIsPaymentModalOpen(true)
            }
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
      fetchUserDetails()
    }
    // Cleanup URL parameters
    window.history.replaceState({}, document.title, window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifySession = async (sessionId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/session-status?session_id=${sessionId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'complete') {
          setHasActiveSubscription(true);
          setIsPaymentModalOpen(false);
          
          user.first_login && setAddTemplateModalVisible(true); // Open AddTemplate modal after successful payment
        } else {
          setHasActiveSubscription(false);
          setIsPaymentModalOpen(true);
          message.warning('You need an active subscription to access all features.');
        }
      } else {
        message.error('Failed to verify payment status.');
      }
    } catch (error) {
      console.error('Error verifying session', error);
      message.error('Error verifying payment.');
    }
  };

  // Initial Check for Subscription Status
  useEffect(() => {
    // If not first login, check subscription status
    if (!user.first_login) {
      checkSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/subscription-status`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        const expiryDate = new Date(result.current_period_end);
        const hasExpired = expiryDate < new Date();
        if (result.current_status === 'active' || (result.current_status === 'canceled' && !hasExpired)) {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
          // message.warning('You need an active subscription to access all features.');
        }
      } else {
        user.bypass_subscription!== true && message.error('No active subscription found');
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      message.error('Error checking subscription status.');
    }
  };

  // Handle AddTemplate Modal Save
  const handleAddTemplateSave = async (values) => {
    setAddTemplateSaving(true); // Start saving
    try {
      // Construct the form data
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('short_description', values.short_description);
      formData.append('public', values.public || false);
      if (values.file && values.file.length > 0) {
        formData.append('file', values.file[0].originFileObj);
      }
      if (values.free_text) {
        formData.append('free_text', values.free_text);
      }

      // Send the POST request to create the template
      const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/create/file`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
          // Note: When using FormData, do not set the 'Content-Type' header. The browser will set it, including the boundary.
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        message.success('Custom template created successfully!');

        // Extract the new template's ID from the response
        // Adjust the path based on your API's response structure
        const newTemplateId = result._id || (result.data && result.data._id);
        if (!newTemplateId) {
          throw new Error('Template ID not found in the response.');
        }

        const selectFormData = new FormData();
        selectFormData.append('template_id', newTemplateId);

        // Automatically set the new template as default
        const selectResponse = await fetch(`${process.env.REACT_APP_API_URL}/templates/user/select`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          body: selectFormData,
        });

        if (selectResponse.ok) {
          message.success('Template set as default successfully!');
        } else {
          const errorData = await selectResponse.json();
          message.error(`Failed to set default template: ${errorData.detail || 'Unknown error'}`);
          // Optionally, you can decide whether to proceed or handle this error differently
        }

        // **Important:** Update the user's first_login status only after successful template creation
        const updateResponse = await fetch(`${process.env.REACT_APP_API_URL}/update_user/first_login`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        });

        if (updateResponse.ok) {
          // Update local storage with the new user details
          const updatedUser = { ...user, first_login: false };
          localStorage.setItem('userDetails', JSON.stringify(updatedUser));
          setHasActiveSubscription(true); 
        } else {
          const errorData = await updateResponse.json();
          message.error(`Failed to update user status: ${errorData.detail || 'Unknown error'}`);
        }

        // Close the AddTemplateModal and reset the form
        setAddTemplateModalVisible(false);
        addTemplateForm.resetFields();
      } else {
        // Handle non-OK responses
        const errorData = await response.json();
        message.error(`Failed to create template: ${errorData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error creating custom template:', error);
      message.error('An error occurred while creating the template.');
    }
    setAddTemplateSaving(false); // End saving
  };

  // Handle AddTemplate Modal Cancel
  const handleAddTemplateCancel = async () => {
    try {
      // **Important:** Do NOT update first_login here if payment was successful
      // Only allow cancellation if it's not mandatory
      if (user.first_login) {
        message.warning('You need to subscribe to access all features.');
      }
    } catch (error) {
      console.error('Error handling template cancel:', error);
      message.error('An error occurred while handling the template cancellation.');
    }
    setAddTemplateModalVisible(false);
    addTemplateForm.resetFields(); // Reset form fields when modal is closed
  };

  // Function to convert markdown to sanitized HTML
  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
    return DOMPurify.sanitize(rawHtml); // Sanitize HTML to prevent XSS attacks
  };

  // Handle search functionality
  const handleSearch = async () => {
    fetchData(searchName, dateRange);
  };

  // Handle date range change
  const onDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch patient data when a new patient is selected
  useEffect(() => {
    if (selectedId) {
      setPatientInterview(false);
      setPatientDataLoading(true);
      setVersionLoading(true);
      fetchPatientData();
      fetchEditedVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  // Fetch transcript and summary when there's a new submission
  useEffect(() => {
    if (newSubmission) {
      setSynopsisLoading(true);
      setTranscriptionLoading(true);
      fetchTranscript();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSubmission]);

  // Fetch selected edited version
  useEffect(() => {
    if (selectedVersion) {
      setEditedSummaryLoading(true);
      fetchSelectedEditedVersion();
    } else {
      setEditedSummary(null);
      setAIGenerated(null);
      setVersions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion]);

  useEffect(() => {
    fetchTemplatesForSynopsis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finalResponse && finalResponse.template_id) {
      setSelectedTemplate(finalResponse.template_id);
    } else {
      setSelectedTemplate(null)
    }
  }, [finalResponse]);
  console.log("selectedTemplate", selectedTemplate)
  // >>> NEW: Helper function to fetch templates and set default preferred template <<<
  const fetchTemplatesForSynopsis = async () => {
    try {
      // Fetch community templates
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      let communityTemplates = [];
      if (response1.ok) {
        const result1 = await response1.json();
        const userDetails = localStorage.getItem("userDetails");
        const user = userDetails ? JSON.parse(userDetails) : {};
        communityTemplates = result1.data.filter(
          (r) =>
            r.community_template &&
            ((r.user_id === null && r.public) || (r.user_id === user.user_id && !r.public))
        );
      }
      // Fetch user-specific templates
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/templates/me`, {
        headers: { Authorization: `Bearer ${auth.user['access_token']}` },
        method: 'GET',
      });
      let customizedPredefinedTemplates = [];
      let yourCustomTemplates = [];
      if (response2.ok) {
        const result2 = await response2.json();
        const userDetails = localStorage.getItem("userDetails");
        const user = userDetails ? JSON.parse(userDetails) : {};
        const userId = user.user_id;
        customizedPredefinedTemplates = result2.data.filter(
          (r) => r.user_id === userId && r.type === 'customizedPredefined'
        );
        yourCustomTemplates = result2.data.filter(
          (r) => r.user_id === userId && r.type === 'customizedFromScratch'
        );
      }
      // Group templates into dropdown options
      const groups = [];
      if (communityTemplates.length > 0) {
        groups.push({
          label: 'Existing Templates',
          options: communityTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      if (customizedPredefinedTemplates.length > 0) {
        groups.push({
          label: 'Customized Predefined Templates',
          options: customizedPredefinedTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      if (yourCustomTemplates.length > 0) {
        groups.push({
          label: 'Your Custom Templates',
          options: yourCustomTemplates.map((template) => ({
            value: template._id,
            label: template.name,
          })),
        });
      }
      setTemplateOptions(groups);
      // Set the default from user details if available:
      try {
        const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
          headers: { Authorization: `Bearer ${auth.user['access_token']}` },
          method: 'GET',
        });
        if (userResponse.ok) {
          const user = await userResponse.json();
          if (user.preferred_template_id) {
            setSelectedTemplate(user.preferred_template_id);
          } else if (groups.length > 0 && groups[0].options.length > 0) {
            setSelectedTemplate(groups[0].options[0].value);
          }
        } else if (groups.length > 0 && groups[0].options.length > 0) {
          setSelectedTemplate(groups[0].options[0].value);
        }
      } catch (err) {
        console.error("Error fetching user details:", err);
        if (groups.length > 0 && groups[0].options.length > 0) {
          setSelectedTemplate(groups[0].options[0].value);
        }
      }
    } catch (error) {
      console.error("Error fetching templates for Synopsis:", error);
      message.error("Error fetching templates.");
    }
  };
  

  // Fetch interview history
  const fetchData = async (searchName = '', dateRange = null) => {
    try {
      const params = new URLSearchParams();

      if (searchName) {
        params.append('name', searchName);
      }

      if (dateRange && dateRange.length === 2) {
        params.append('start_date', dateRange[0].toISOString());
        params.append('end_date', dateRange[1].toISOString());
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        setData(result.data);
        if (result.data.length > 0) {
          setSelectedId(result.data[0]._id);
          setPatientId(result.data[0].patient_id);
        }
      } else {
        message.error('Failed to fetch interview history');
      }
    } catch (error) {
      console.error('Error fetching interview history', error);
      message.error('Error fetching interview history');
    }
    setPageLoading(false);
  };

  // Fetch specific patient data
  const fetchPatientData = async (fromTranscription = false) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        setFinalResponse(result);
        setSelectedLanguage(result.target_language_code);
      } else {
        message.error('Failed to fetch interview information');
      }
    } catch (error) {
      console.error('Error fetching interview information', error);
      message.error('Error fetching interview information');
    }
    setPageLoading(false);
    setPatientDataLoading(false);
    fromTranscription && setTranscriptionLoading(false);
  };

  // Placeholder for fetching patient interview
  const fetchPatientInterview = async () => {
    // Implement your logic here
    setPatientInterview(true);
    setPatientInterviewLoading(false);
  };

  // Fetch edited summary versions
  const fetchEditedVersions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/summary/versions`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        if (result.version_history) {
          const options = result.version_history.map((item) => ({
            label: item.version,
            value: item.version,
          }));
          setVersions(options);
          setSelectedVersion(options[options.length - 1].value);
        } else {
          setVersions([]);
          setSelectedVersion(null);
        }
      } else {
        message.error('Failed to fetch Edited Summary History');
      }
    } catch (error) {
      console.error('Error fetching Edited Summary History', error);
      message.error('Error fetching Edited Summary History');
    }
    setVersionLoading(false);
  };

  // Fetch selected edited summary version
  const fetchSelectedEditedVersion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/summary/version/${selectedVersion}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        }
      );

      if (response.ok) {
        const result = await response.json();
        setEditedSummary(result.summary);
        setAIGenerated(result.ai_generated);
      } else {
        message.error('Failed to fetch Edited Summary');
      }
    } catch (error) {
      console.error('Error fetching Edited Summary', error);
      message.error('Error fetching Edited Summary');
    }
    setEditedSummaryLoading(false);
  };

  // Handle sending email
  const handleSendEmail = async () => {
    setSendEmailLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/send_patient_note`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();
        message.success(result.message);
      } else {
        message.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email', error);
      message.error('Error sending email');
    }
    setSendEmailLoading(false);
  };

  // Fetch transcript
  const fetchTranscript = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/files/audio/${selectedId}/generate_transcript`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();

        await fetchPatientData(true);
        newSubmission && fetchSummary();
      } else {
        message.error('Failed to generate transcript');
        setNewSubmission(false);
        setTranscriptionLoading(false);
      }
    } catch (error) {
      console.error('Error generating transcript', error);
      message.error('Error generating transcript');
      setNewSubmission(false);
      setTranscriptionLoading(false);
    }
  };

  // Fetch summary
  const fetchSummary = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/files/audio/${selectedId}/generate_summary`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();

        fetchPatientData();
      } else {
        message.error('Failed to generate summary');
      }
    } catch (error) {
      console.error('Error generating summary', error);
      message.error('Error generating summary');
    }
    setSynopsisLoading(false);
    setNewSubmission(false);
  };

  // Handle deleting patient entry
  const handleDeletePatient = async () => {
    setDeletePatientEntryLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/delete/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'DELETE',
      });

      if (response.ok) {
        const result = await response.json();

        message.success('Patient entry deleted successfully');
        fetchData();
      } else {
        message.error('Failed to delete patient entry');
      }
    } catch (error) {
      console.error('Error deleting patient entry', error);
      message.error('Error deleting patient entry');
    }
    setDeletePatientEntryLoading(false);
  };

  // Confirmation for Summary Regeneration
  const confirmRegenerateSummary = () => {
    Modal.confirm({
      title: 'Confirm Regeneration',
      content: 'Do you want to regenerate the summary?',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: () => {
        setSynopsisLoading(true);
        fetchSummary();
      },
    });
  };

  // Confirmation for Transcript Regeneration
  const confirmRegenerateTranscript = () => {
    Modal.confirm({
      title: 'Confirm Regeneration',
      content: 'Do you want to regenerate the transcription?',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: () => {
        setTranscriptionLoading(true);
        fetchTranscript();
      },
    });
  };

  // Confirmation for Fetching Interview Recording
  const confirmFetchInterview = () => {
    Modal.confirm({
      title: 'Confirm Action',
      content: 'Do you want to fetch the interview recording?',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: () => {
        setPatientInterviewLoading(true);
        fetchPatientInterview(selectedId);
      },
    });
  };

  // Utility function to replace underscores with spaces
  function replaceUnderscoreWithSpace(name) {
    if (name.includes('_')) {
      return name.replace(/_/g, ' ');
    }
    return name; // If there are no underscores, return the name as is.
  }

  // Conditionally disable features based on subscription status
  const isFeatureDisabled = !hasActiveSubscription;

  return (
    <>
      <Content style={{ overflow: 'initial', maxHeight: '90vh', overflow: 'auto' }} className="site-layout-background">
        {pageLoading && versionLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Row style={{ height: '820px', display: 'flex', padding: '10px' }}>
            <Col lg={6} sm={24} xs={24} style={{ padding: '10px', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Card style={{ padding: '10px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Row>
                  <Col span={24} style={{ height: '720px', overflow: 'auto' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        size={'large'}
                        onClick={() => {
                          if (hasActiveSubscription) {
                            setModalVisible(true);
                          } else {
                            message.warning('You need an active subscription to start a new interview.');
                          }
                        }}
                        disabled={isFeatureDisabled} // Disable if no active subscription
                      >
                        Start New Interview
                      </Button>
                    </div>
                    <Divider style={{ marginBottom: '15px' }}></Divider>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col span={24}>
                        <Input
                          placeholder="Search by patient's name"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          allowClear
                          style={{ width: '100%' }}
                          disabled={isFeatureDisabled} // Disable if no active subscription
                        />
                      </Col>
                      <Col span={24} style={{ marginTop: '10px' }}>
                        <Space direction="horizontal">
                          <RangePicker onChange={onDateRangeChange} style={{ width: '100%' }} disabled={isFeatureDisabled} />
                          <Button type="primary" onClick={handleSearch} disabled={isFeatureDisabled}>
                            <SearchOutlined />
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                    <List
                      pagination={{
                        position: 'bottom',
                        align: 'center',
                        pageSize: 6,
                      }}
                      dataSource={data}
                      renderItem={(item, index) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar src={playIcon} />}
                            title={
                              <div style={{ display: 'flex', alignItems: 'center', fontWeight: item._id === selectedId ? 'bold' : 'normal' }}>
                                {item.name}
                                <Tooltip placement="top" title="Delete Patient Entry">
                                  <DeleteOutlined
                                    style={{ color: 'red', marginLeft: '15px', fontSize: '16px', cursor: 'pointer' }}
                                    onClick={() => setOpenConfirmDeleteModal(true)}
                                  />
                                </Tooltip>
                              </div>
                            }
                            description={
                              // <span style={{ fontWeight: item._id === selectedId ? 'bold' : 'normal' }}>
                              //   {`${new Date(item.upload_time).toLocaleDateString("en-CA", { timeZone: "America/New_York" })} ${new Date(item.upload_time).toLocaleTimeString("en-US", { timeZone: "America/New_York", hour12: false })} (EST)`}
                              // </span>
                              <span style={{ fontWeight: item._id === selectedId ? 'bold' : 'normal' }}>
                                {(() => {
                                  // Convert stored IST timestamp (without TZ info) to a valid ISO string with IST offset.
                                  const istDate = new Date(item.upload_time.replace(' ', 'T') + '+05:30');

                                  // Format the date/time for EST using "America/New_York"
                                  const dateInEST = istDate.toLocaleDateString('en-CA', {
                                    timeZone: 'America/New_York',
                                  });
                                  const timeInEST = istDate.toLocaleTimeString('en-US', {
                                    timeZone: 'America/New_York',
                                    hour12: false,
                                  });

                                  return `${dateInEST} ${timeInEST} (EST)`;
                                })()}
                              </span>
                            }
                            onClick={() => {
                              setSelectedId(item._id);
                              setPatientId(item.patient_id);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                          {item._id === selectedId && <Badge count="." style={{ backgroundColor: '#00db87', marginLeft: '10px' }} />}
                        </List.Item>

                      )}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={18} sm={24} xs={24} style={{ padding: '0px 10px', height: '100%', display: 'flex', flexDirection: 'column' }}>
              {patientDataLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <Row style={{ display: 'flex', height: '100%' }}>
                    {/* Left Column */}
                    <Col
                      lg={16}
                      sm={24}
                      xs={24}
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {finalResponse && (
                        <>
                          {/* Summary Card */}
                          <Card
                            style={{
                              padding: '10px',
                              flex: '1 1 auto',
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: patientInterview ? '10px' : '0px',
                              overflow: 'auto',
                            }}
                          >
                            {/* Summary card content */}
                            <Row>
                              <Col lg={8} md={24} sm={24} xs={24}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography.Title level={5} style={{ margin: 0 }}>
                                    Summary
                                  </Typography.Title>
                                  <Tooltip placement="top" title="Regenerate Summary">
                                    <Button
                                      shape="circle"
                                      type="default"
                                      icon={<RedoOutlined style={{ fontSize: '12px' }} />}
                                      style={{ marginLeft: '15px', width: '30px', height: '30px' }}
                                      onClick={() => {
                                        if (hasActiveSubscription) {
                                          confirmRegenerateSummary();
                                        } else {
                                          message.warning('You need an active subscription to regenerate the summary.');
                                        }
                                      }}
                                      disabled={isFeatureDisabled} // Disable if no active subscription
                                    />
                                  </Tooltip>
                                </div>
                              </Col>
                              <Col lg={16} md={16} sm={24} xs={24} style={{ textAlign: 'right' }}>
                                <Typography.Text style={{ margin: 0, fontSize: '12px', fontWeight: 600 }}>
                                  Selected Template:{' '}
                                </Typography.Text>
                                {selectedTemplate?
                                  <Select
                                  size='small'
                                    value={selectedTemplate}
                                    placeholder="Select Template"
                                    style={{ width: '250px' }}
                                    options={templateOptions}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => {
                                      Modal.confirm({
                                        title: 'Confirm Template Change',
                                        content: 'Are you sure you want to use this template for this summary?',
                                        okText: 'Yes',
                                        cancelText: 'No',
                                        centered: true,
                                        onOk: async () => {
                                          setSelectedTemplate(value);
                                          const formData = new FormData();
                                          formData.append('template_id', value);
                                          try {
                                            const response = await fetch(
                                              `${process.env.REACT_APP_API_URL}/templates/user/select`,
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${auth.user['access_token']}`,
                                                },
                                                method: 'POST',
                                                body: formData,
                                              }
                                            );
                                            if (response.ok) {
                                              message.success('The default template has been successfully updated, and the medical note is now being regenerated using the new template.', 7);
                                              setSynopsisLoading(true);
                                              fetchSummary();
                                            } else {
                                              message.error('Failed to update default template');
                                            }
                                          } catch (err) {
                                            console.error('Error updating default template', err);
                                            message.error('Error updating default template');
                                          }
                                        },
                                        onCancel() {
                                          // Do nothing if cancelled
                                        },
                                      });
                                    }}
                                /> : 
                                finalResponse.template_name ? 
                                finalResponse.template_name :
                                "Not Mentioned"
                              }
                              </Col>
                            </Row>
                            <Divider style={{ marginTop: '10px' }} />
                            <div style={{ flexGrow: 1 }}>
                              {/* This area will expand and be scrollable */}
                              <Collapse
                                accordion
                                activeKey={[activePanel]}
                                onChange={(v) => setActivePanel(v)}
                              >
                                <Panel header="AI Generated Summary" key="01">
                                  {synopsisLoading ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '20px',
                                      }}
                                    >
                                      <Spin size="large" />
                                    </div>
                                  ) : (
                                    <>
                                      <Row style={{ paddingBottom: '10px' }}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                          {finalResponse.summary !== null && (
                                            <IconButtonGroup
                                              text={finalResponse.summary}
                                              feature={{
                                                edit: hasActiveSubscription, // Only allow editing if subscribed
                                                copy: true,
                                                download: true,
                                                email: true,
                                                fullscreen: true,
                                              }}
                                              fileId={selectedId}
                                              setSelectedVersion={setSelectedVersion}
                                              fetchEditedVersions={fetchEditedVersions}
                                              setActivePanel={setActivePanel}
                                              contentType={'summary'}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      <div style={{ maxHeight: patientInterview ? '180px' : '430px', overflow: 'auto' }}>
                                        {/* Scrollable content */}
                                        {finalResponse.summary == null ? (
                                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Summary Found'} />
                                        ) : (
                                          <div
                                            className="message-content"
                                            dangerouslySetInnerHTML={{
                                              __html: markdownToHtml(finalResponse.summary),
                                            }}
                                          />
                                        )}
                                      </div>
                                    </>
                                  )}
                                </Panel>
                                {versions.length > 0 && (
                                  <Panel header="Edited Summary" key="02">
                                    <Row style={{ paddingBottom: '10px' }}>
                                      <Col lg={8} md={8} sm={24} xs={24}>
                                        <label
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            marginTop: '5px',
                                            marginRight: '15px',
                                          }}
                                        >
                                          Select Version:{' '}
                                        </label>
                                        <Select
                                          showSearch
                                          placeholder="Select version"
                                          optionFilterProp="label"
                                          onChange={(v) => setSelectedVersion(v)}
                                          value={selectedVersion}
                                          options={versions}
                                          style={{ width: '50%' }}
                                          disabled={isFeatureDisabled} // Disable if no active subscription
                                        />
                                      </Col>
                                      <Col lg={8} md={8} sm={24} xs={24}>
                                        <Typography.Text style={{ margin: 0, fontSize: '12px', fontWeight: 600 }}>
                                          <b style={{ color: '#146ef5' }}>{aiGenerated && 'Regenerated AI Summary'}</b>
                                        </Typography.Text>
                                      </Col>
                                      <Col lg={8} md={8} sm={24} xs={24} style={{ textAlign: 'right' }}>
                                        <IconButtonGroup
                                          text={editedSummary == null ? '' : editedSummary}
                                          feature={{
                                            edit: hasActiveSubscription, // Only allow editing if subscribed
                                            copy: true,
                                            download: true,
                                            email: true,
                                            fullscreen: true,
                                          }}
                                          fileId={selectedId}
                                          setSelectedVersion={setSelectedVersion}
                                          fetchEditedVersions={fetchEditedVersions}
                                          setActivePanel={setActivePanel}
                                          versionId={selectedVersion}
                                          contentType={'summary'}
                                        />
                                      </Col>
                                    </Row>
                                    {editSummaryLoading ? (
                                      <div
                                        style={{
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          display: 'flex',
                                          padding: '20px',
                                        }}
                                      >
                                        <Spin size="large" />
                                      </div>
                                    ) : (
                                      <div style={{ maxHeight: patientInterview ? '180px' : '430px', overflow: 'auto' }}>
                                        {/* Scrollable content */}
                                        <div
                                          className="message-content"
                                          dangerouslySetInnerHTML={{
                                            __html: markdownToHtml(editedSummary == null ? '' : editedSummary),
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Panel>
                                )}
                              </Collapse>
                            </div>
                            <Text style={{ fontSize: '10px' }}>
                              <i>AI can make mistakes. Please share your feedback with us!</i>
                            </Text>
                          </Card>

                          {/* Patient Interview Card */}
                          {patientInterview && (
                            <Card
                              style={{
                                padding: '10px',
                                flex: '1 1 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '10px',
                              }}
                            >
                              {patientInterviewLoading ? (
                                <div
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    padding: '20px',
                                    height: '100%',
                                  }}
                                >
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <Row>
                                    <Col span={12}>
                                      <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                                        {replaceUnderscoreWithSpace(finalResponse.name)}
                                      </Typography.Title>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                      {/* Placeholder for audio length or other info */}
                                    </Col>
                                  </Row>
                                  <Divider />
                                  <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                    <Waveform audioUrl={finalResponse.file_path} />
                                  </div>
                                </>
                              )}
                            </Card>
                          )}
                        </>
                      )}
                    </Col>

                    {/* Right Column */}
                    <Col
                      lg={8}
                      sm={24}
                      xs={24}
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {finalResponse && (
                        <>
                          {/* Transcript Card */}
                          <Card
                            style={{
                              padding: '10px',
                              flex: '1 1 auto',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Row>
                              <Col lg={14} md={14} sm={24} xs={24}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography.Title level={5} style={{ margin: 0 }}>
                                    Transcript
                                  </Typography.Title>
                                  <Tooltip placement="top" title="Regenerate Transcription">
                                    <Button
                                      shape="circle"
                                      type="default"
                                      icon={<RedoOutlined style={{ fontSize: '12px' }} />}
                                      style={{ marginLeft: '15px', width: '30px', height: '30px' }}
                                      onClick={() => {
                                        if (hasActiveSubscription) {
                                          confirmRegenerateTranscript();
                                        } else {
                                          message.warning('You need an active subscription to regenerate the transcription.');
                                        }
                                      }}
                                      disabled={isFeatureDisabled} // Disable if no active subscription
                                    />
                                  </Tooltip>
                                  <Tooltip placement="top" title="Fetch Interview Recording">
                                    <Button
                                      size="small"
                                      shape="circle"
                                      type="default"
                                      icon={<CaretRightOutlined />}
                                      style={{
                                        marginLeft: '15px',
                                        backgroundColor: 'transparent',
                                        color: '#2196F3',
                                        borderColor: '#2196F3',
                                        marginTop: '3px',
                                      }}
                                      onClick={() => {
                                        if (hasActiveSubscription) {
                                          confirmFetchInterview();
                                        } else {
                                          message.warning('You need an active subscription to fetch interview recordings.');
                                        }
                                      }}
                                      loading={patientInterviewLoading}
                                      disabled={isFeatureDisabled} // Disable if no active subscription
                                    />
                                  </Tooltip>
                                </div>
                              </Col>
                              <Col lg={10} md={10} sm={24} xs={24} style={{ textAlign: 'right' }}>
                                {finalResponse.transcription !== null && (
                                  <IconButtonGroup
                                    text={finalResponse.transcription[selectedLanguage] == null ? '' : finalResponse.transcription[selectedLanguage]}
                                    feature={{
                                      edit: false,
                                      copy: true,
                                      download: true,
                                      email: false,
                                      fullscreen: true,
                                    }}
                                    fileId={selectedId}
                                    contentType={'transcript'}
                                  />
                                )}
                              </Col>
                            </Row>
                            <Divider style={{ margin: 0, marginTop: '5px' }} />
                            <div style={{ flexGrow: 1 }}>
                              {/* This area will expand and be scrollable */}
                              <div style={{ height: '450px', overflow: 'auto' }}>
                                {/* Scrollable content */}
                                {transcriptionLoading ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '20px',
                                    }}
                                  >
                                    <Spin size="large" />
                                  </div>
                                ) : finalResponse.transcription == null ? (
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Transcription Found'} />
                                ) : (
                                  <div
                                    className="message-content"
                                    dangerouslySetInnerHTML={{
                                      __html: markdownToHtml(
                                        finalResponse.transcription[selectedLanguage] == null
                                          ? ''
                                          : finalResponse.transcription[selectedLanguage]
                                      ),
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </Card>

                          {/* Action Card */}
                          <Card
                            style={{
                              padding: '10px',
                              marginTop: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Row>
                              <Col span={24}>
                                <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                                  Action
                                </Typography.Title>
                              </Col>
                            </Row>
                            <Divider />
                            <Row style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                              <Col lg={8} sm={24} xs={24} style={{ textAlign: 'center' }}>
                                <Tooltip placement="top" title="Generate Assessment Report">
                                  <Button
                                    type="default"
                                    style={{
                                      height: '50px',
                                      width: '50px',
                                      borderColor: '#0074D9',
                                      marginBottom: '5px',
                                    }}
                                    onClick={() => {
                                      if (hasActiveSubscription) {
                                        setIsAssessmentModalOpen(true);
                                      } else {
                                        message.warning('You need an active subscription to generate assessment reports.');
                                      }
                                    }}
                                    disabled={isFeatureDisabled || !finalResponse.summary}
                                  >
                                    <FileTextOutlined style={{ fontSize: '30px', color: '#0074D9' }} />
                                  </Button>
                                </Tooltip>
                              </Col>
                              <Col lg={8} sm={24} xs={24} style={{ textAlign: 'center' }}>
                                <Tooltip placement="top" title="View Patient Details">
                                  <Button
                                    type="default"
                                    style={{
                                      height: '50px',
                                      width: '50px',
                                      borderColor: '#0074D9',
                                      marginBottom: '5px',
                                    }}
                                    onClick={() => setIsEditModalOpen(true)}
                                    disabled={isFeatureDisabled}
                                  >
                                    <FolderViewOutlined style={{ fontSize: '30px', color: '#0074D9' }} />
                                  </Button>
                                </Tooltip>
                              </Col>
                              <Col lg={8} sm={24} xs={24} style={{ textAlign: 'center' }}>
                                <Tooltip placement="top" title="View Patient Notes">
                                  <Button
                                    type="default"
                                    style={{
                                      height: '50px',
                                      width: '50px',
                                      borderColor: '#0074D9',
                                      marginBottom: '5px',
                                    }}
                                    onClick={() => {
                                      if (hasActiveSubscription) {
                                        setIsPatientNoteModalOpen(true);
                                      } else {
                                        message.warning('You need an active subscription to view patient notes.');
                                      }
                                    }}
                                    disabled={isFeatureDisabled || !finalResponse.summary}
                                  >
                                    {sendEmailLoading ? (
                                      <LoadingOutlined style={{ fontSize: '30px', color: '#0074D9' }} />
                                    ) : (
                                      <ProfileOutlined style={{ fontSize: '30px', color: '#0074D9' }} />
                                    )}
                                  </Button>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Card>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        )}
        {/* Payment Modal */}
        <PaymentModal
          visible={isPaymentModalOpen}
          onCancel={handlePaymentCancel}
          onPay={handlePaymentAction}
          loading={paymentLoading}
          action={paymentAction}       // ← Add this prop
          onSuccess={handlePaymentSuccess} // ← Add this prop
        />


        {/* AddTemplate Modal */}
        <AddTemplateModal
          visible={addTemplateModalVisible}
          onCancel={handleAddTemplateCancel}
          onSave={handleAddTemplateSave}
          saving={addTemplateSaving}
          form={addTemplateForm}
          themeMode={themeMode}
          mandatory={true}
        />

        {/* Other Modals */}
        <RecordingModal
          open={modalVisible}
          setOpen={setModalVisible}
          setSelectedId={setSelectedId}
          fetchData={fetchData}
          setNewSubmission={setNewSubmission}
          setSelectedLanguage={setSelectedLanguage}
        />
        <EditPatientModal open={isEditModalOpen} setOpen={setIsEditModalOpen} patientId={patientId} />
        <AssessmentModal open={isAssessmentModalOpen} setOpen={setIsAssessmentModalOpen} fileId={selectedId} />
        <PatientNoteModal
          open={isPatientNoteModalOpen}
          setOpen={setIsPatientNoteModalOpen}
          fileId={selectedId}
          handleSendEmail={handleSendEmail}
          sendEmailLoading={sendEmailLoading}
        />
        <ConfirmDeletePatientModal
          open={openConfirmDeleteModal}
          setOpen={setOpenConfirmDeleteModal}
          handleDeletePatient={handleDeletePatient}
          loading={deletePatientEntryLoading}
        />
        {finalResponse && finalResponse.summary && <StickyChat title="Scribe Chatbot" fileId={selectedId} patientId={patientId} type={"summary"} />}
      </Content>
    </>
  );
};

export default Synopsis;
